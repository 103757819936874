export const sceneList = [
  'topview',
  'topview-tang-07',
  'topview-tang-15',
  'topview-tang-22',
  'topview-tang-27',
];

export const mikaSceneList = [
  'mika-topview',
  'mika-topview-tang-07',
  'mika-topview-tang-15',
  'mika-topview-tang-25',
  'mika-topview-tang-27',
];

export const apartmentList = [
  'can-ho-1pn-scene-1',
  'can-ho-1pn-scene-2',
  'can-ho-1pn-scene-3',
  'can-ho-1pn-scene-4',
  'can-ho-1pn-scene-5',
  'can-ho-1pn-scene-6',
];

export const mikaTopMenu = [
  {
    id: 'mika-topview-tang-07',
    title: 'Mika Topview Tầng 06',
  },
  {
    id: 'mika-topview-tang-15',
    title: 'Mika Topview Tầng 12',
  },
  // {
  //   id: 'mika-topview-tang-22',
  //   title: 'Mika Topview Tầng 18',
  // },
  {
    id: 'mika-topview-tang-25',
    title: 'Mika Topview Tầng 18',
  },
  {
    id: 'mika-topview-tang-27',
    title: 'Mika Topview Tầng 24',
  },
];
