import React from 'react';

export const MapIcon = (props) => {
  return (
    <svg
      id="icon-3d_map"
      data-name="icon-3d map"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_14242"
            data-name="Rectangle 14242"
            width="16"
            height="16"
            fill="#fff"
            strokeWidth={1}
          />
        </clipPath>
      </defs>
      <g id="Group_3977" data-name="Group 3977">
        <path
          id="Path_1552"
          data-name="Path 1552"
          d="M147.249,25.272c-4.418,0-7.75-1.632-7.75-3.8,0-1.449,1.492-2.7,3.991-3.346a.75.75,0,1,1,.376,1.452C141.9,20.09,141,20.92,141,21.475c0,.937,2.435,2.3,6.25,2.3s6.25-1.36,6.25-2.3c0-.557-.9-1.387-2.873-1.9A.75.75,0,1,1,151,18.126c2.5.646,4,1.9,4,3.349,0,2.165-3.332,3.8-7.75,3.8"
          transform="translate(-139.249 -9.895)"
          fill="#fff"
        />
        <path
          id="Path_1553"
          data-name="Path 1553"
          d="M151.427,9.434a3.25,3.25,0,1,1,3.25-3.25,3.254,3.254,0,0,1-3.25,3.25m0-5a1.75,1.75,0,1,0,1.75,1.75,1.752,1.752,0,0,0-1.75-1.75"
          transform="translate(-143.588 -2.311)"
          fill="#fff"
        />
        <path
          id="Path_1554"
          data-name="Path 1554"
          d="M154.239,18.733a.75.75,0,0,1-.75-.75V14.156a.75.75,0,0,1,1.5,0v3.826a.75.75,0,0,1-.75.75"
          transform="translate(-146.244 -7.547)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
