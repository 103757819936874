import React from 'react';

import './ApartmentPopup.scss';

export default function ApartmentPopup({ handleApartmentPopup, onSelect }) {
  return (
    <div className="apartment-popup" onClick={handleApartmentPopup}>
      <div className="apartment-popup--container">
        <button
          className="apartment-popup--cancle"
          onClick={handleApartmentPopup}
        >
          x
        </button>
        <div className="apartment-popup--btns">
          <button
            className="apartment-popup--btn"
            onClick={() => onSelect('can-ho-mau', 'can-ho-1pn-scene-1')}
          >
            CĂN HỘ 1 PHÒNG NGỦ +
          </button>
          <button
            className="apartment-popup--btn"
            onClick={() => onSelect('can-ho-mau', '2')}
          >
            CĂN HỘ 2 PHÒNG NGỦ
          </button>
        </div>
      </div>
    </div>
  );
}
