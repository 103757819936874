import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { isMobile } from 'react-device-detect';
import CalloutLine from './calloutLine';
import { Html } from './Html';
import CanvasCircle from './canvasCircle';
import Canvas from './canvas';
import { CANVAS_W, CANVAS_H } from './utils';
import HotspotContainer from './hotspotContainer';
import HotspotIcon from '../icons';
import MEDIA_TYPES from 'consts/mediaType.const';
import {
  get2DScreenPosition,
  distanceBetween2dCoordinates,
} from 'utils/positionHelper';
import { getCenterScreen } from 'containers/world/utils';
import { trackTourContentVisible } from 'gaTracking';
import { useSelector } from 'react-redux';
import { HOTSPOT_UI } from 'consts/ui.const';
import { checkIsShowHotspotMap } from 'utils';
import CanvasPulsePoint from './canvasPulsePoint';
import MediaHotspot from './mediaHotspotLine';
import IndoorHotspot from './sceneIndoorHotspot';
import OutdoorHotspot from './sceneOutdoorHotspot';
import PrimaryHotspot from './primaryTopviewHotspot';

import './lineSingle.scss';

const MAX_VISIBLE_DISTANCE = 150;
const HOVERzINDEX = '100';
const zIndexRange1 = [50, 0];
const zIndexRangeHover = [100, 100];

const styles = {
  width: `${CANVAS_W}px`,
  height: `${CANVAS_H}px`,
  pointerEvents: 'none',
};

const MEDIA_DATA = {
  'tong-the-toan-canh-01': {
    'landmark-81': 3.5,
    'cong-vien': 3.3,
    'truong-mam-non-': 3.2,
    'truong-thcs-': 2.6,
    'truong-th-': 2.2,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    'kdt-zeitgest': 3,
    'truong-th': 4.2,
    'truong-thcs': 3.4,
    'truong-mam-non': 2.2,
    kdt: 2,
    'truong-quoc-te': 2,
    _: 3,
  },
  'tong-the-toan-canh-01---ban-o8em': {
    'landmark-81': 2,
    'truong-th': 3.5,
    'truong-mam-non': 3.1,
    'truong-thcs': 2.9,
    kdt: 2,
    'cong-vien': 3.5,
    'truong-mam-non-': 3.8,
    'truong-thcs-': 3.5,
    'truong-th-': 3,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    'kdt-zeitgest': 2,
    'truong-quoc-te': 1.5,
    _: 3,
  },
  'tong-the-toan-canh-02': {
    'landmark-81': 2,
    'truong-th': 3,
    'truong-thcs': 2.8,
    'truong-mam-non': 2,
    kdt: 2,
    'truong-quoc-te': 2,
    'kdt-zeitgest': 3,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    'cong-vien': 2,
    'truong-mam-non-': 2,
    'truong-thcs-': 2,
    'truong-th-': 2,
    _: 2,
  },
  'tong-the-toan-canh-02---ban-wcem': {
    'landmark-81': 2,
    kdt: 2,
    'truong-quoc-te': 2,
    _: 2,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    'cong-vien': 2.5,
  },
  'topview-tang-27': {
    'landmark-81': 3,
    'truong-th': 3,
    'truong-thcs': 2.5,
    'truong-mam-non': 3,
    kdt: 2.5,
    'song-long-kieng': 3,
    'truong-quoc-te': 2.5,
    'kdt-zeitgest': 2.6,
    'cong-vien': 3,
    'truong-mam-non-': 2,
    'truong-thcs-': 2,
    'truong-th-': 1.5,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    _: 2,
  },
  'topview-tang-22': {
    'landmark-81': 3,
    'truong-th': 3,
    'truong-thcs': 2.5,
    'truong-mam-non': 3,
    kdt: 2.5,
    'song-long-kieng': 3,
    'truong-quoc-te': 2.5,
    'kdt-zeitgest': 2.6,
    'cong-vien': 3,
    'truong-mam-non-': 2,
    'truong-thcs-': 2,
    'truong-th-': 1.5,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    _: 2,
  },
  'topview-tang-15': {
    'landmark-81': 3,
    'truong-th': 3.5,
    'truong-thcs': 2.5,
    'truong-mam-non': 3,
    'cau-long-kieng': 2,
    kdt: 2.5,
    'song-long-kieng': 3,
    'truong-quoc-te': 2.5,
    'kdt-zeitgest': 2.6,
    'cong-vien': 3,
    'truong-mam-non-': 2.5,
    'truong-thcs-': 2,
    'truong-th-': 1.5,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    _: 2,
  },

  'mika-topview-tang-27': {
    'landmark-81': 3,
    'truong-th': 3,
    'truong-thcs': 2.2,
    'truong-mam-non': 2.5,
    kdt: 2.5,
    'song-long-kieng': 3,
    'truong-quoc-te': 2.6,
    'kdt-zeitgest': 2.6,
    'cong-vien': 3,
    'truong-mam-non-': 2,
    'truong-thcs-': 2,
    'truong-th-': 1.5,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    _: 2,
  },

  'mika-topview-tang-25': {
    'landmark-81': 3,
    'truong-th': 3,
    'truong-thcs': 2.5,
    'truong-mam-non': 3,
    kdt: 2.5,
    'song-long-kieng': 3,
    'truong-quoc-te': 2.5,
    'kdt-zeitgest': 2.6,
    'cong-vien': 3,
    'truong-mam-non-': 2,
    'truong-thcs-': 2,
    'truong-th-': 1.5,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    _: 2,
  },

  'mika-topview-tang-22': {
    'landmark-81': 3,
    'truong-th': 3,
    'truong-thcs': 2.2,
    'truong-mam-non': 2.5,
    kdt: 2.5,
    'song-long-kieng': 3,
    'truong-quoc-te': 2.6,
    'kdt-zeitgest': 2.6,
    'cong-vien': 3,
    'truong-mam-non-': 2,
    'truong-thcs-': 2,
    'truong-th-': 1.5,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    _: 2,
  },

  'mika-topview-tang-15': {
    'landmark-81': 3,
    'truong-th': 3.5,
    'truong-thcs': 2.5,
    'truong-mam-non': 2.5,
    'cau-long-kieng': 2,
    kdt: 2.5,
    'song-long-kieng': 3,
    'truong-quoc-te': 2.5,
    'kdt-zeitgest': 2.6,
    'cong-vien': 3,
    'truong-mam-non-': 2.5,
    'truong-thcs-': 2,
    'truong-th-': 1.5,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    _: 2,
  },
  'topview-tang-07': {
    'cau-long-kieng': 2,
    'truong-th': 2,
    'truong-thcs': 1.8,
    'truong-mam-non': 3,
    'song-long-kieng': 3,
    kdt: 2.5,
    'kdt-zeitgest': 2.6,
    clubhouse: 2,
    '-yiuong-dao-bo-ven-song--': 2.5,
    'cong-vien': 3,
    'sales-gallery': 2.5,
    'truong-mam-non-': 2,
    'truong-thcs-': 2,
    'truong-th-': 1.5,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    _: 2,
  },

  'mika-topview-tang-07': {
    'cau-long-kieng': 2,
    'landmark-81': 2.5,
    'truong-th': 2,
    'truong-thcs': 1.8,
    'song-long-kieng': 3,
    kdt: 1.8,
    'kdt-zeitgest': 1.6,
    clubhouse: 2,
    'cong-vien': 1.5,
    'truong-mam-non-': 2,
    'truong-thcs-': 2,
    'truong-th-': 1.5,
    'kcn-hiep-phuoc': 2,
    'vanh-ncai-3---cao-toc': 2,
    _: 2,
  },
};

const checkMediaLineHeight = (currentPano, hotspot) => {
  const heights = MEDIA_DATA[currentPano.id]?.[hotspot.id];
  if (heights) {
    return heights;
  }
  return 1;
};

const LineSingle = (props) => {
  const { media, hotspot, currentPano } = props;
  const { camera } = useThree();
  const canvasRef = useRef();
  const hotspotRef = useRef();
  const elRef = useRef();
  const [zIndex, setZIndex] = useState('0');
  const [zIndexRange, setZIndexRange] = useState(zIndexRange1);
  const [hovering, setHovering] = useState(false);
  const trackingRef = useRef(false);
  const params = useSelector(({ searchParams }) => searchParams);
  const hotspotStyle = useSelector(({ tour }) => tour.hotspotStyle);
  const isAquaStyle = useSelector(({ tour }) => tour.menuStyle === 'aqua');
  const isPrimeStyle = useSelector(({ tour }) => tour.menuStyle === 'prime');

  const isCalloutStyle = useSelector(
    ({ searchParams }) => searchParams['hotspot-ui'] === HOTSPOT_UI.CALLOUT
  );
  const isTag = useMemo(() => {
    return media?.type === MEDIA_TYPES.INFO_TAG;
  }, [media]);

  const hoverToggled = (isHover) => {
    const parentNode = elRef.current && elRef.current.parentNode;
    setHovering(Boolean(isHover));
    if (isHover) {
      setZIndexRange(zIndexRangeHover);
      props.setHotspotHovering(true);
      if (parentNode) {
        setZIndex(parentNode.style.zIndex);
        parentNode.style.zIndex = HOVERzINDEX;
      }
    } else {
      props.setHotspotHovering(false);
      setTimeout(() => {
        if (elRef && elRef.current) {
          setZIndexRange(zIndexRange1);
          if (parentNode) {
            parentNode.style.zIndex = zIndex;
          }
        }
      }, 0);
    }
  };

  useFrame(() => {
    if (isMobile) {
      const [x, y, z] = props.position;
      const xyHotSpotPos = get2DScreenPosition(
        new THREE.Vector3(x, y, z),
        camera,
        window.innerWidth,
        window.innerHeight
      );
      const screenCenter = getCenterScreen();
      const distance = distanceBetween2dCoordinates(xyHotSpotPos, screenCenter);
      if (distance <= MAX_VISIBLE_DISTANCE) {
        setHovering(true);
      } else {
        setHovering(false);
      }
    }
  });

  const onHotspotVisible = useCallback(() => {
    if (props.media && !trackingRef.current) {
      trackTourContentVisible({
        media_id: props.media._id,
        tour_id: props.tour.id,
        scene_id: props.currentPano._id,
      });

      trackingRef.current = true;
    }
  }, [props.media, props.currentPano._id, props.tour.id]);

  const isShowMapFloorPlanButton = useMemo(() => {
    return checkIsShowHotspotMap(hotspot);
  }, [hotspot]);

  const configsData = useMemo(() => {
    if (!props?.hotspot?.configs) return null;
    return JSON.parse(props?.hotspot?.configs);
  }, [props?.hotspot?.configs]);

  const mediaHeight = useMemo(() => {
    if (!media) return 0;
    return checkMediaLineHeight(currentPano, hotspot, media);
  }, [currentPano, hotspot, media]);

  if (media) {
    return (
      <Html
        ref={elRef}
        position={props.position}
        center={true}
        zIndexRange={zIndexRange}
        // style={styles}
        onVisible={onHotspotVisible}
        className="hotspot-khp-container"
      >
        <MediaHotspot
          media={media}
          height={mediaHeight}
          goToScene={props.goToScene}
          handleApartmentPopup={props.handleApartmentPopup}
        />
      </Html>
    );
  }

  if (!configsData && props.scene) {
    return (
      <Html
        ref={elRef}
        position={props.position}
        center={true}
        zIndexRange={zIndexRange}
        // style={styles}
        onVisible={onHotspotVisible}
        className="hotspot-khp-container"
      >
        <IndoorHotspot scene={props.scene} onClick={props.onClick} />
      </Html>
    );
  }

  if (configsData && props.scene) {
    const isPrimary =
      configsData?.title === 'Topview' &&
      (configsData?.subtitle === 'Tháp VINCI' ||
        configsData?.subtitle === 'Tháp MIKA');

    if (isPrimary) {
      return (
        <Html
          ref={elRef}
          position={props.position}
          center={true}
          zIndexRange={[555, 666]}
          // style={styles}
          onVisible={onHotspotVisible}
          className="hotspot-khp-container"
        >
          <PrimaryHotspot
            scene={props.scene}
            configsData={configsData}
            onClick={props.onClick}
          />
        </Html>
      );
    }

    return (
      <Html
        ref={elRef}
        position={props.position}
        center={true}
        zIndexRange={zIndexRange}
        // style={styles}
        onVisible={onHotspotVisible}
        className="hotspot-khp-container"
      >
        <OutdoorHotspot scene={props.scene} onClick={props.onClick} />
      </Html>
    );
  }

  if (
    hotspotStyle === HOTSPOT_UI.CALLOUT ||
    isTag ||
    isCalloutStyle ||
    isAquaStyle ||
    isPrimeStyle
  ) {
    return (
      <CalloutLine
        ref={elRef}
        hotspotRef={hotspotRef}
        canvasRef={canvasRef}
        position={props.position}
        center={true}
        zIndexRange={zIndexRange}
        style={styles}
        onVisible={onHotspotVisible}
        forceUp={props.forceUp}
        isAquaStyle={isAquaStyle}
        isPrimeStyle={isPrimeStyle}
        configsData={configsData}
      >
        <>
          {isAquaStyle || isPrimeStyle ? (
            <>
              <Canvas ref={canvasRef} />
              {configsData &&
              configsData.baseIconType === 'circle_animation' ? (
                <CanvasPulsePoint isPrimeStyle={isPrimeStyle} />
              ) : (
                <CanvasCircle />
              )}
            </>
          ) : (
            <>
              <CanvasCircle />
              <Canvas ref={canvasRef} />
            </>
          )}

          <HotspotContainer ref={hotspotRef}>
            <HotspotIcon
              type={props.type}
              hotspot={props.hotspot}
              currentSceneCoords={props.currentSceneCoords}
              scene={props.scene}
              media={props.media}
              tour={props.tour}
              onClick={props.onClick}
              isHover={hovering}
              toggleHover={(val) => hoverToggled(val)}
              hoverIn={() => hoverToggled(true)}
              hoverOut={() => hoverToggled(false)}
              color={props.color || ''}
              scope={props.scope}
              hotspotStyle={hotspotStyle}
              isShowMapFloorPlanButton={isShowMapFloorPlanButton}
            />
          </HotspotContainer>
        </>
      </CalloutLine>
    );
  }

  return (
    <Html
      ref={elRef}
      position={props.position}
      center={true}
      zIndexRange={zIndexRange}
      style={styles}
      onVisible={onHotspotVisible}
    >
      <HotspotContainer>
        <HotspotIcon
          type={props.type}
          hotspot={props.hotspot}
          currentSceneCoords={props.currentSceneCoords}
          scene={props.scene}
          media={props.media}
          tour={props.tour}
          onClick={props.onClick}
          isHover={hovering}
          toggleHover={(val) => hoverToggled(val)}
          hoverIn={() => hoverToggled(true)}
          hoverOut={() => hoverToggled(false)}
          color={props.color || ''}
          scope={props.scope}
          params={params}
          hotspotStyle={hotspotStyle}
          isShowMapFloorPlanButton={isShowMapFloorPlanButton}
        />
      </HotspotContainer>
    </Html>
  );
};

export default React.memo(LineSingle);
