import configs from '../configs';

export const searchByQuery = async (query) => {
  const res = await fetch(`${configs.apiUrl}Tours/SearchByQuery`, {
    method: 'POST',
    body: JSON.stringify({ Query: query }),
  });
  const data = await res.json();
  return data;
};

export default searchByQuery;
