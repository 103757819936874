import React from 'react';

export const AutoRotateIcon = ({ shouldRotate, activeColor }) => {
  return shouldRotate ? (
    <svg
      id="Component_40_1"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="25"
      viewBox="0 0 36 36"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_14249"
            data-name="Rectangle 14249"
            width="36"
            height="36"
            transform="translate(1768 42)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_66"
        data-name="Mask Group 66"
        transform="translate(-1768 -42)"
      >
        <g id="Group_3999" data-name="Group 3999">
          <path
            id="Path_1547"
            data-name="Path 1547"
            d="M13.727,27.435A13.709,13.709,0,0,1,0,13.721a1.47,1.47,0,1,1,2.94,0,10.78,10.78,0,1,0,3.52-7.97A1.47,1.47,0,0,1,4.48,3.578a13.721,13.721,0,1,1,9.247,23.857"
            transform="matrix(0.966, 0.259, -0.259, 0.966, 1777.284, 43.3)"
            fill={activeColor}
          />
          <path
            id="Path_1548"
            data-name="Path 1548"
            d="M1.469,8.537A1.47,1.47,0,0,1,.184,6.354L3.706,0l6.355,3.521A1.47,1.47,0,1,1,8.635,6.093L4.853,4,2.756,7.78a1.468,1.468,0,0,1-1.287.758"
            transform="matrix(0.966, 0.259, -0.259, 0.966, 1771.209, 52.934)"
            fill={activeColor}
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      id="Component_40_1"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="25"
      viewBox="0 0 36 36"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_14249"
            data-name="Rectangle 14249"
            width="36"
            height="36"
            transform="translate(1768 42)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_66"
        data-name="Mask Group 66"
        transform="translate(-1768 -42)"
      >
        <g id="Group_3999" data-name="Group 3999">
          <path
            id="Path_1547"
            data-name="Path 1547"
            d="M13.727,27.435A13.709,13.709,0,0,1,0,13.721a1.47,1.47,0,1,1,2.94,0,10.78,10.78,0,1,0,3.52-7.97A1.47,1.47,0,0,1,4.48,3.578a13.721,13.721,0,1,1,9.247,23.857"
            transform="matrix(0.966, 0.259, -0.259, 0.966, 1777.284, 43.3)"
            fill="#fff"
          />
          <path
            id="Path_1548"
            data-name="Path 1548"
            d="M1.469,8.537A1.47,1.47,0,0,1,.184,6.354L3.706,0l6.355,3.521A1.47,1.47,0,1,1,8.635,6.093L4.853,4,2.756,7.78a1.468,1.468,0,0,1-1.287.758"
            transform="matrix(0.966, 0.259, -0.259, 0.966, 1771.209, 52.934)"
            fill="#fff"
          />
        </g>
      </g>
      <g
        id="Mask_Group_4002"
        data-name="Mask Group 4002"
        transform="translate(-1758 -42)"
      >
        <g id="Group_4001" data-name="Group 4001" transform="translate(-10)">
          <path
            id="Line_157"
            data-name="Line 157"
            d="M0,32.5a1.5,1.5,0,0,1-1.061-.439,1.5,1.5,0,0,1,0-2.121l31-31a1.5,1.5,0,0,1,2.121,0,1.5,1.5,0,0,1,0,2.121l-31,31A1.5,1.5,0,0,1,0,32.5Z"
            transform="translate(1770.5 44.5)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
