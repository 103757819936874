import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const ORBIT_MODE = 'orbit';
export const GyroscopeIcon = ({ activeColor }) => {
  const { controlMode } = useSelector((state) => state);
  const isGyrosControlMode = useMemo(
    () => (controlMode === ORBIT_MODE ? true : false),
    [controlMode]
  );

  return isGyrosControlMode ? (
    <svg
      id="Component_42_1"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="21"
      viewBox="0 0 36 36"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_14248"
            data-name="Rectangle 14248"
            width="36"
            height="36"
            transform="translate(1692 42)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_65"
        data-name="Mask Group 65"
        transform="translate(-1692 -42)"
      >
        <g id="Group_3998" data-name="Group 3998" transform="translate(0 -0.5)">
          <path
            id="Line_158"
            data-name="Line 158"
            d="M8,1.5H0A1.5,1.5,0,0,1-1.5,0,1.5,1.5,0,0,1,0-1.5H8A1.5,1.5,0,0,1,9.5,0,1.5,1.5,0,0,1,8,1.5Z"
            transform="translate(1706 59.5)"
            fill="#fff"
          />
          <path
            id="Rectangle_14244"
            data-name="Rectangle 14244"
            d="M2-1.5h8A3.5,3.5,0,0,1,13.5,2V6.5h-15V2A3.5,3.5,0,0,1,2-1.5Zm8.5,5V2a.5.5,0,0,0-.5-.5H2a.5.5,0,0,0-.5.5V3.5Z"
            transform="translate(1704 49)"
            fill="#fff"
          />
          <path
            id="Path_1561"
            data-name="Path 1561"
            d="M26.566,20h-5.1a3.091,3.091,0,0,1-2.253-.991l-2.566-2.556a.067.067,0,0,0-.12,0l-.043.047-2.719,2.529A3.09,3.09,0,0,1,11.533,20h-5.1A7.657,7.657,0,0,1-1,12.153V6.847A7.657,7.657,0,0,1,6.434-1H26.566A7.658,7.658,0,0,1,34,6.847v5.306A7.658,7.658,0,0,1,26.566,20Zm-7.726-5.594,2.566,2.556a.1.1,0,0,0,.06.038h5.1A4.66,4.66,0,0,0,31,12.153V6.847A4.66,4.66,0,0,0,26.566,2H6.434A4.66,4.66,0,0,0,2,6.847v5.306A4.66,4.66,0,0,0,6.434,17h5.1a.1.1,0,0,0,.06-.038l.043-.048,2.719-2.53a3.057,3.057,0,0,1,4.486.021Z"
            transform="translate(1693.5 53.5)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      id="Component_42_1"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="21"
      viewBox="0 0 36 36"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_14248"
            data-name="Rectangle 14248"
            width="36"
            height="36"
            transform="translate(1692 42)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_65"
        data-name="Mask Group 65"
        transform="translate(-1692 -42)"
      >
        <g id="Group_3998" data-name="Group 3998" transform="translate(0 -0.5)">
          <path
            id="Line_158"
            data-name="Line 158"
            d="M8,1.5H0A1.5,1.5,0,0,1-1.5,0,1.5,1.5,0,0,1,0-1.5H8A1.5,1.5,0,0,1,9.5,0,1.5,1.5,0,0,1,8,1.5Z"
            transform="translate(1706 59.5)"
            fill={activeColor}
          />
          <path
            id="Rectangle_14244"
            data-name="Rectangle 14244"
            d="M2-1.5h8A3.5,3.5,0,0,1,13.5,2V6.5h-15V2A3.5,3.5,0,0,1,2-1.5Zm8.5,5V2a.5.5,0,0,0-.5-.5H2a.5.5,0,0,0-.5.5V3.5Z"
            transform="translate(1704 49)"
            fill={activeColor}
          />
          <path
            id="Path_1561"
            data-name="Path 1561"
            d="M26.566,20h-5.1a3.091,3.091,0,0,1-2.253-.991l-2.566-2.556a.067.067,0,0,0-.12,0l-.043.047-2.719,2.529A3.09,3.09,0,0,1,11.533,20h-5.1A7.657,7.657,0,0,1-1,12.153V6.847A7.657,7.657,0,0,1,6.434-1H26.566A7.658,7.658,0,0,1,34,6.847v5.306A7.658,7.658,0,0,1,26.566,20Zm-7.726-5.594,2.566,2.556a.1.1,0,0,0,.06.038h5.1A4.66,4.66,0,0,0,31,12.153V6.847A4.66,4.66,0,0,0,26.566,2H6.434A4.66,4.66,0,0,0,2,6.847v5.306A4.66,4.66,0,0,0,6.434,17h5.1a.1.1,0,0,0,.06-.038l.043-.048,2.719-2.53a3.057,3.057,0,0,1,4.486.021Z"
            transform="translate(1693.5 53.5)"
            fill={activeColor}
          />
        </g>
      </g>
    </svg>
  );
};
