import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

function searchData(props) {
  const { data } = props;
  return (
    <div className="item">
      <div className="item--wrapper">
        <div
          className="item--wrapper-img"
          style={{ backgroundImage: `url(${data.introImageUrl})` }}
        >
          {/* <img src={data.introImageUrl} alt={data.tagline} /> */}
          {/* <img src={data.introImageUrl} alt='travel tour' /> */}
        </div>
        <div className="item--wrapper-content">
          {/* <p className='data-travel-content-title'>{data.name}</p> */}
          <Link
            to={`/${data.id}`}
            target="_blank"
            className="item--wrapper-content__title"
          >
            {data.name}
          </Link>
          <p className="item--wrapper-content__title-label">{data.location}</p>
          <p className="item--wrapper-content__title-tagline">
            {data.tagline.length > 500
              ? data.tagline.substr(0, 500) + '...'
              : data.tagline}
          </p>
        </div>
      </div>
      <div className="item--wrapper-content__title-tagline in-md-screen">
        {data.tagline.length > 200
          ? data.tagline.substr(0, 200) + '...'
          : data.tagline}
      </div>
      {/* <p className='data-item-text'>{data.text}</p> */}
    </div>
  );
}

export default searchData;
