import React, { useMemo } from 'react';

const IconAerialScene = ({ hightLightColor, visited }) => {
  const color = useMemo(
    () => (visited ? hightLightColor : 'black'),
    [visited, hightLightColor]
  );
  return (
    <svg
      data-name="Aerial Scene"
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      transform="translate(14 15)"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_14"
            data-name="Rectangle 14"
            width="31"
            height="31"
            transform="translate(0 0)"
            fill="black"
            stroke="black"
            strokeWidth="0.5"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_35"
            data-name="Rectangle 35"
            width="30.936"
            height="30.936"
            transform="translate(0.171 0.162)"
            fill="black"
            stroke="black"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <path
        id="Path_95"
        data-name="Path 95"
        d="M15.17,0A15.17,15.17,0,1,1,0,15.17,15.17,15.17,0,0,1,15.17,0Z"
        transform="translate(0.33 0.33)"
        fill={color}
        stroke="#fff"
        strokeWidth="1"
      />
      <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(0 0)">
        <g
          id="Mask_Group_3"
          data-name="Mask Group 3"
          transform="translate(-0.162 -0.162)"
        >
          <g
            id="Group_219"
            data-name="Group 219"
            transform="translate(6.563 7.413)"
          >
            <path
              id="Path_177"
              data-name="Path 177"
              d="M8.75,17.612v5.356a.866.866,0,0,0,1.564.513l3.133-4.264Z"
              transform="translate(-3.284 -7.401)"
              fill="#fff"
            />
            <path
              id="Path_178"
              data-name="Path 178"
              d="M16.924.09a.582.582,0,0,0-.559-.035L.288,7.675A.48.48,0,0,0,0,8.143a.5.5,0,0,0,.361.423L4.832,9.953,14.35,2.567,6.984,10.62l7.49,2.323a.6.6,0,0,0,.173.026.58.58,0,0,0,.279-.071.489.489,0,0,0,.252-.344l1.965-12A.469.469,0,0,0,16.924.09Z"
              transform="translate(0 0)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconAerialScene;
