import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from 'assets/locales/en/translation.json';
import vi from 'assets/locales/vi/translation.json';
import jp from 'assets/locales/jp/translation.json';

const options = {
  // order and from where user language should be detected
  order: ['querystring', 'localStorage', 'navigator', 'path'],

  // keys or params to lookup language from
  lookupLocalStorage: 'i18nextLng',
  lookupQuerystring: 'lang',

  // cache user language on
  caches: ['localStorage'],
};

const resource = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
  jp: {
    translation: jp,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources: resource,
    fallbackLng: 'en',
    supportedLngs: ['en', 'vi', 'jp'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
