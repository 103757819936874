import IconHuman from 'containers/hotspots/icons/iconHuman';
import React, { useMemo, useState } from 'react';

export default function AquaMenuItem({
  scenes,
  scene,
  onSelect,
  currentPano,
  setOpenMobileMenu,
}) {
  const [hover, setHover] = useState(null);

  const hoverImage = useMemo(
    () => scenes.find((item) => item._id === scene._id),
    [scenes, scene]
  );

  const isMobile = useMemo(() => window.innerWidth < 1023, []);

  return (
    <div
      className={`menu-items-aqua__scenes-item ${
        currentPano.id === scene.id ? 'currentScene' : ''
      }`}
      // onMouseOver={() => setHover(scene.order)}
      onMouseLeave={() => setHover(null)}
      onClick={() => {
        onSelect(scene.groupId, scene.id);
        setOpenMobileMenu && setOpenMobileMenu();
      }}
    >
      {isMobile && (
        <IconHuman
          style={{ height: 20 }}
          color={
            hover === scene.order || currentPano.id === scene.id
              ? '#ffffff'
              : '#052044e6'
          }
        />
      )}

      <p
        className={`menu-items-aqua__scenes-item-title ${
          hover === scene.order || currentPano.id === scene.id ? 'hovering' : ''
        }`}
      >
        {scene.title}
      </p>

      {!isMobile && (
        <IconHuman
          style={{ height: 20 }}
          color={
            hover === scene.order || currentPano.id === scene.id
              ? '#ffffff'
              : '#052044e6'
          }
        />
      )}

      {!isMobile && (
        <div
          className="menu-items-aqua__scenes-item-image"
          style={{ opacity: hover === scene.order ? 1 : 0 }}
        >
          <img src={hoverImage.previewImgUrl} alt={hoverImage.title} />
          <div className="menu-items-aqua__scenes-item-imgTitle">
            <p> {scene.title}</p>
          </div>
        </div>
      )}
    </div>
  );
}
