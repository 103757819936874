import React, { useRef, memo, useEffect, useState, useMemo } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { gsap } from 'gsap';
import configs from 'configs';
import { updateVector3OrPointByRotationXZ } from 'containers/world/utils';
import { BOX_WIDTH } from 'containers/world/constant';
import { TRANSITION_METHOD } from '../transition.const';
import { SIDE } from './constants';
import CrossfadeMaterial from './materials/material';
import { createTripodMarker } from './tripodMarker';
import SceneInteractionTracker from './SceneInteractionTracker';
import { degToRad } from 'three/src/math/MathUtils';

const SCALE_RANGE = {
  BIG: 10,
  NORMAL: 1,
};

function CubeEffect({
  texture0,
  texture1,
  mixFactor,
  stepHandlers,
  tripodMarkerUrl,
  pano,
  getCameraParams,
  getSceneRotation,
}) {
  const meshRef = useRef(null);
  const [opacity, setOpacity] = useState(0);
  const boxArgs = useMemo(() => [BOX_WIDTH, BOX_WIDTH, BOX_WIDTH, 1, 1, 1], []);
  const sceneViewRef = useRef();
  const { camera, raycaster } = useThree();

  function findCenterPointLookAt() {
    // Set the raycaster position based on the center point look at and camera
    raycaster.setFromCamera({ x: 0, y: 0 }, camera);

    // Find the intersection of the raycaster and the box object
    const intersects = raycaster.intersectObject(meshRef.current);
    const { x, y, z } = intersects[0].point;
    return [x, y, z];
  }

  useFrame(() => {
    if (meshRef.current && pano) {
      const lookAtPoint = findCenterPointLookAt();
      getCameraParams({ lookAtPoint, fov: camera.fov });
    }
  });

  useEffect(() => {
    meshRef.current.scale.set(
      -SCALE_RANGE.NORMAL,
      SCALE_RANGE.NORMAL,
      SCALE_RANGE.NORMAL
    );
    meshRef.current.updateMatrix();
  }, [meshRef]);

  useEffect(() => {
    if (tripodMarkerUrl) {
      createTripodMarker(tripodMarkerUrl).then((marker) => {
        meshRef.current.add(marker);
      });
    }
  }, [tripodMarkerUrl]);

  useEffect(() => {
    const sceneId = stepHandlers.sceneData?.id || '';
    meshRef.current.name = sceneId;
  }, [meshRef, stepHandlers]);

  useEffect(() => {
    const { active, stepPosition, rotations } = stepHandlers;
    setOpacity(active ? 1 : 0);
    const shouldAnimate =
      window.transitionMethod === TRANSITION_METHOD.GROUND_NAV;
    const [prevRot, currRot] = rotations.slice(-2);
    getSceneRotation({ prevRotation: prevRot, currRotation: currRot });
    if (active) {
      meshRef.current.rotation.y = currRot?.rad || 0;
      meshRef.current.updateMatrix();
      // animation to show the scene
      if (shouldAnimate) {
        let fromPosition = updateVector3OrPointByRotationXZ(
          stepPosition,
          prevRot?.rad || 0
        );
        fromPosition = fromPosition.normalize().multiplyScalar(1500);
        const transformData = {
          x: fromPosition.x,
          z: fromPosition.z,
          scale: SCALE_RANGE.BIG,
        };
        meshRef.current.position.copy(fromPosition);
        meshRef.current.scale.set(
          -SCALE_RANGE.BIG,
          SCALE_RANGE.BIG,
          SCALE_RANGE.BIG
        );
        gsap.to(transformData, {
          x: 0,
          z: 0,
          scale: SCALE_RANGE.NORMAL,
          duration: configs.transitionSpeed,
          onUpdate: () => {
            const { x, z, scale } = transformData;
            meshRef.current.position.x = x;
            meshRef.current.position.z = z;
            meshRef.current.scale.set(-scale, scale, scale);
            meshRef.current.updateMatrix();
          },
        });
      }
    } else {
      // animation to hide the scene away
      if (shouldAnimate) {
        const { positionNext } = stepHandlers;
        meshRef.current.position.set(0, 0, 0);
        meshRef.current.updateMatrix();
        let nextPosition = updateVector3OrPointByRotationXZ(
          positionNext,
          prevRot?.rad || 0
        );
        nextPosition = nextPosition.normalize();
        nextPosition = nextPosition.multiplyScalar(1500);
        setTimeout(() => {
          meshRef.current.position.copy(nextPosition);
          meshRef.current.scale.set(
            -SCALE_RANGE.BIG,
            SCALE_RANGE.BIG,
            SCALE_RANGE.BIG
          );
          meshRef.current.updateMatrix();
        }, configs.transitionSpeed * 0.9 * 1000);
      }
    }
    // eslint-disable-next-line
  }, [stepHandlers]);

  const mouseUp = () => {
    sceneViewRef.current.handleMouseUp();
  };

  const mouseDown = () => {
    sceneViewRef.current.handleMouseDown();
  };

  useEffect(() => {
    if (pano && pano.id === 'mika-topview-tang-07') {
      if (meshRef.current) {
        meshRef.current.rotation.y += degToRad(48);
        meshRef.current.updateMatrix();
      }
    }

    if (pano && pano.id === 'mika-topview-tang-25') {
      if (meshRef.current) {
        meshRef.current.rotation.y += degToRad(-22);
        meshRef.current.updateMatrix();
      }
    }

    if (pano && pano.id === 'can-ho-1pn-scene-1') {
      if (meshRef.current) {
        meshRef.current.rotation.y += degToRad(-87);
        meshRef.current.updateMatrix();
      }
    }

    if (pano && pano.id === 'can-ho-1pn-scene-3') {
      if (meshRef.current) {
        meshRef.current.rotation.y += degToRad(45);
        meshRef.current.updateMatrix();
      }
    }

    if (pano && pano.id === 'can-ho-1pn-scene-4') {
      if (meshRef.current) {
        meshRef.current.rotation.y += degToRad(165);
        meshRef.current.updateMatrix();
      }
    }

    if (pano && pano.id === 'can-ho-1pn-scene-5') {
      if (meshRef.current) {
        meshRef.current.rotation.y += degToRad(165);
        meshRef.current.updateMatrix();
      }
    }

    if (pano && pano.id === 'can-ho-1pn-scene-6') {
      if (meshRef.current) {
        meshRef.current.rotation.y += degToRad(166);
        meshRef.current.updateMatrix();
      }
    }
  }, [pano]);

  return (
    <mesh
      ref={meshRef}
      matrixAutoUpdate={false}
      onPointerUp={mouseUp}
      onPointerDown={mouseDown}
      // onClick={(e) => console.log(`[${e.point.x}, ${e.point.y}, ${e.point.z}]`)}
    >
      <SceneInteractionTracker
        ref={sceneViewRef}
        meshRef={meshRef}
        pano={pano}
      />
      <boxGeometry attach="geometry" args={boxArgs} />
      {SIDE.map((side, index) => (
        <CrossfadeMaterial
          key={side}
          texture1={texture0[side]}
          texture2={texture1[side]}
          mixFactor={mixFactor}
          index={index}
          opacity={opacity}
        />
      ))}
    </mesh>
  );
}

export default memo(CubeEffect);
