import React, { useRef, useEffect } from 'react';
import { CANVAS_W, CANVAS_H, CANVAS_STYLE } from './utils';

export const CanvasMarker = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      window.logMessage('draw canvas');
      const ctx = ref.current.getContext('2d');
      ctx.clearRect(0, 0, CANVAS_W, CANVAS_H);

      const centerX = CANVAS_W / 2;
      const centerY = CANVAS_H / 2;
      const radius = 6; // Radius of the circle part of the marker
      const triangleHeight = 12; // Height of the triangular point

      // Draw the circle part of the marker
      ctx.beginPath();
      ctx.arc(centerX, centerY - triangleHeight / 2, radius, 0, 2 * Math.PI);
      ctx.fillStyle = 'white';
      ctx.fill();
      // ctx.lineWidth = 2;
      //ctx.strokeStyle = 'white';
      //ctx.stroke();

      // Draw the triangular point of the marker
      ctx.beginPath();
      ctx.moveTo(centerX - radius, centerY - triangleHeight / 2);
      ctx.lineTo(centerX, centerY + triangleHeight / 2);
      ctx.lineTo(centerX + radius, centerY - triangleHeight / 2);
      ctx.closePath();
      ctx.fillStyle = 'white';
      ctx.fill();
      //ctx.strokeStyle = 'white';
      //ctx.stroke();

      // Draw the inner white circle
      ctx.beginPath();
      ctx.arc(centerX, centerY - triangleHeight / 2, radius / 4, 0, 2 * Math.PI);
      ctx.fillStyle = '#213b64';
      ctx.fill();
    }
  }, [ref]);

  return (
    <canvas ref={ref} width={CANVAS_W} height={CANVAS_H} style={CANVAS_STYLE} />
  );
};

export default React.memo(CanvasMarker);
