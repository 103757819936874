import React from 'react';

import './style.scss';
import SunIcon from './SunIcon';
import MoonIcon from './MoonIcon';

export default function DayTimeSwitch({ daylight, setDaylight }) {
  return (
    <div
      className={`daytime-switch ${daylight ? '' : 'night'}`}
      onClick={setDaylight}
    >
      <div className={`daytime-switch-icon ${daylight ? '' : 'night'}`}>
        {daylight ? <SunIcon /> : <MoonIcon />}
      </div>
      <div className={`daytime-switch-text ${daylight ? '' : 'night'}`}>
        <span className="dt-text-content">{daylight ? 'DAY' : 'NIGHT'}</span>
      </div>
    </div>
  );
}
