// import configs from '../configs';

const doNothing = () => null;
// window.logMessage = configs.prod ? doNothing : console.log;
window.logMessage = doNothing;
window.isoDateString = () => new Date().toISOString();
window.waitASecond = (sec = 1) =>
  new Promise((resolve) => {
    window.logMessage(`wait ${sec}s... ${window.isoDateString()}`);
    setTimeout(() => {
      resolve(true);
      window.logMessage(`waited ${sec}s... ${window.isoDateString()}`);
    }, sec * 1000);
  });

export const log = window.logMessage;
