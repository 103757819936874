import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigatedRoutes } from 'common/hooks';

import { canFullscreen, toggleFullScreen } from 'utils';

import PanoMenu from 'components/panoMenu';
import {
  AutoRotateIcon,
  ContactFormIcon,
  FloorMapIcon,
  GyroscopeIcon,
  MapIcon,
  AudioIcon,
  HelpIcon,
  PinIcon,
  MapFloorPlanIcon,
  ExpandIcon,
  ShareIcon,
} from 'components/icons';
import VZIcon from 'assets/images/logo/vz-logo.png';
import { AutoPlayIcon } from 'components/icons/AutoPlayIcon';
import GalleryMenu from 'components/GalleryMenu';

import { setAutoPlay } from 'store/actions';
import configs from 'configs';
import SchoolLayoutMenu from 'components/SchoolLayoutMenu';
import { LAYOUT_UI } from 'consts/ui.const';
import SchoolLayoutIcons from 'components/MenuForDesktop/SchoolLayoutIcons';
import AACorporationLayout from 'components/AACorporationLayout';
import { limitLetters } from 'utils/limitLetters';
import AquaLayoutIcons from 'components/MenuForDesktop/AquaLayoutIcons';
import PrimeLayoutIcons from 'components/MenuForDesktop/PrimeLayoutIcons';
import KhaiHoanPrimeLayout from 'components/KhaiHoanPrimeLayout';
import { isMobile } from 'react-device-detect';
import MapfloorplanModal from 'components/MapfoorplanModal';

const MenuForMobile = (props) => {
  const {
    menuList,
    isNavOpen,
    menuPosition,
    currentPano,
    subTitle,
    showLanding,
    menuOpenScene,
    toggleRotate,
    toggleMenu,
    shouldRotate,
    handleSwitchControlMode,
    featuredMedia,
    color,
    isShowOnMap,
    isShowAudioIcon,
    tour,
    onSelect,
    groups,
    scenes,
    isShowMapFloorPlan,
    floorMaps,
    cameraParams,
    sceneRotation,
  } = props;

  const {
    tour: { enableGyroscope },
  } = useSelector((state) => state);
  const [handleUpdateRoute] = useNavigatedRoutes();
  const autoPlayMode = useSelector((state) => state.autoPlayMode);
  const [isAutoPlay, setIsAutoPlay] = useState(autoPlayMode);
  const [fullScreen, setFullScreen] = useState(false);
  const [isActiveMap, setIsActiveMap] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [mapShowed, setMapShowed] = useState();

  const [isOpenMapfloorplanModal, setIsOpenMapfloorplanModal] = useState(false);
  const isMenuGallery = useSelector(
    ({ searchParams }) => searchParams['menu-ui'] === 'gallery'
  );
  const [openMenu, setOpenMenu] = useState(
    !(tour.menuStyle === LAYOUT_UI.SCHOOL)
  );

  useEffect(() => {
    setOpenDropDown(false);
  }, [currentPano]);

  // const autoRotateSpeed = useSelector((state) => state.tour.autoRotateSpeed);

  const shouldShowMapIcon = useMemo(
    () =>
      tour.mapCoordinates.length > 0 &&
      !(tour.mapCoordinates[0] === 0 && tour.mapCoordinates[1] === 0),
    [tour]
  );

  const isSchoolLayout = useMemo(
    () => tour.menuStyle === LAYOUT_UI.SCHOOL,
    [tour]
  );

  const isAquaStyle = useMemo(
    () => tour.menuStyle === LAYOUT_UI.AACORPORATION,
    [tour]
  );

  const isPrimeStyle = useMemo(
    () => tour.menuStyle === LAYOUT_UI.KHAIHOANPRIME,
    [tour]
  );

  const mediaMap2D = useMemo(
    () =>
      currentPano?.hotspots
        .map((hs) =>
          hs.media?.type === 'MapFloorPlan' && hs.media.mapFloorPlan.length
            ? hs.media
            : null
        )
        .filter((el) => el !== null)[0] || null,
    [currentPano]
  );

  const toggleHelpButton = () => {
    showLanding();
  };

  const dispatch = useDispatch();

  const switchMode = useCallback(() => {
    setIsAutoPlay(!isAutoPlay);
    dispatch(setAutoPlay(!isAutoPlay));
  }, [dispatch, isAutoPlay]);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleFullScreen = () => {
    toggleFullScreen();
    setFullScreen(!fullScreen);
  };

  const handleSelectMap = (item) => {
    setMapShowed(item);
  };

  useEffect(() => {
    if (floorMaps.length > 0 && currentPano) {
      const getCurrentMap = (floorMaps, pano) => {
        return floorMaps.find((item) =>
          item.objects.length
            ? item.objects.some((obj) => obj.id === pano._id)
            : item
        );
      };

      const intialMap = getCurrentMap(floorMaps, currentPano);

      setMapShowed(intialMap);
    }
  }, [currentPano, floorMaps]);

  return (
    <>
      <div
        className={`menuV2-mobile--container ${
          isAquaStyle || isPrimeStyle ? 'menuV2-mobile--container-aqua' : ''
        } ${isPrimeStyle ? 'menuV2-mobile--container-prime' : ''}`}
      >
        <div
          className={`menuV2-mobile--icons ${isNavOpen ? 'navMenuOpen' : ''}
          ${
            isAquaStyle || isPrimeStyle ? 'menuV2-mobile--icons-aquaIcons' : ''
          } ${isPrimeStyle ? 'menuV2-mobile--icons-primeIcons' : ''}`}
        >
          {!isSchoolLayout && !isAquaStyle && !isPrimeStyle && (
            <div className="vz-logo">
              <img alt="vz-logo" src={VZIcon}></img>
            </div>
          )}

          {isSchoolLayout && (
            <SchoolLayoutIcons
              currentPano={currentPano}
              isAutoPlay={isAutoPlay}
              color={color}
              toggleHelpButton={toggleHelpButton}
              featuredMedia={featuredMedia}
              isShowAudioIcon={isShowAudioIcon}
              toggleRotate={toggleRotate}
              shouldRotate={shouldRotate}
              canFullscreen={canFullscreen}
              menuPosition={menuPosition}
              handleFullScreen={handleFullScreen}
              fullScreen={fullScreen}
              isShowMapFloorPlan={isShowMapFloorPlan}
              setIsActiveMap={setIsActiveMap}
              isActiveMap={isActiveMap}
              mapShowed={mapShowed}
              setOpenDropDown={setOpenDropDown}
              openDropDown={openDropDown}
              handleSelectMap={handleSelectMap}
              scenes={scenes}
              onSelect={onSelect}
              switchMode={switchMode}
              handleUpdateRoute={handleUpdateRoute}
              openMenu={openMenu}
              isSchoolLayout={isSchoolLayout}
              enableGyroscope={enableGyroscope}
              handleSwitchControlMode={handleSwitchControlMode}
            />
          )}

          {isAquaStyle && (
            <AquaLayoutIcons
              currentPano={currentPano}
              isAutoPlay={isAutoPlay}
              color={color}
              toggleHelpButton={toggleHelpButton}
              featuredMedia={featuredMedia}
              isShowAudioIcon={isShowAudioIcon}
              toggleRotate={toggleRotate}
              shouldRotate={shouldRotate}
              canFullscreen={canFullscreen}
              menuPosition={menuPosition}
              handleFullScreen={handleFullScreen}
              fullScreen={fullScreen}
              isShowMapFloorPlan={isShowMapFloorPlan}
              setIsActiveMap={setIsActiveMap}
              isActiveMap={isActiveMap}
              mapShowed={mapShowed}
              setOpenDropDown={setOpenDropDown}
              openDropDown={openDropDown}
              handleSelectMap={handleSelectMap}
              scenes={scenes}
              onSelect={onSelect}
              switchMode={switchMode}
              handleUpdateRoute={handleUpdateRoute}
              isSchoolLayout={isSchoolLayout}
              isAquaStyle={isAquaStyle}
              shouldShowMapIcon={shouldShowMapIcon}
              isShowOnMap={isShowOnMap}
            />
          )}

          {isPrimeStyle && (
            <PrimeLayoutIcons
              currentPano={currentPano}
              isAutoPlay={isAutoPlay}
              color={color}
              toggleHelpButton={toggleHelpButton}
              featuredMedia={featuredMedia}
              isShowAudioIcon={isShowAudioIcon}
              toggleRotate={toggleRotate}
              shouldRotate={shouldRotate}
              canFullscreen={canFullscreen}
              menuPosition={menuPosition}
              handleFullScreen={handleFullScreen}
              fullScreen={fullScreen}
              isShowMapFloorPlan={isShowMapFloorPlan}
              setIsActiveMap={setIsActiveMap}
              isActiveMap={isActiveMap}
              mapShowed={mapShowed}
              setOpenDropDown={setOpenDropDown}
              openDropDown={openDropDown}
              handleSelectMap={handleSelectMap}
              scenes={scenes}
              onSelect={onSelect}
              switchMode={switchMode}
              handleUpdateRoute={handleUpdateRoute}
              isSchoolLayout={isSchoolLayout}
              isAquaStyle={isAquaStyle}
              shouldShowMapIcon={shouldShowMapIcon}
              isShowOnMap={isShowOnMap}
              isPrimeStyle={isPrimeStyle}
              setIsOpenMapfloorplanModal={() =>
                setIsOpenMapfloorplanModal(true)
              }
            />
          )}

          {!isSchoolLayout && !isAquaStyle && !isPrimeStyle && (
            <div
              className={`icons ${
                openMenu && (isMenuGallery || isSchoolLayout)
                  ? 'translateY'
                  : ''
              }`}
            >
              <div
                className="icons_icon"
                onClick={() => {
                  switchMode();
                }}
              >
                <AutoPlayIcon shouldRotate={isAutoPlay} activeColor={color} />
              </div>
              <div className="icons_icon" onClick={toggleHelpButton}>
                <HelpIcon />
              </div>
              {shouldShowMapIcon && (
                <div
                  className="icons_icon"
                  style={{
                    display: isShowOnMap ? 'flex' : 'none',
                  }}
                  onClick={() => handleUpdateRoute(configs.mapRoute)}
                >
                  <MapIcon />
                </div>
              )}

              {featuredMedia.map && (
                <div
                  className="icons_icon icon-floor"
                  onClick={() => handleUpdateRoute(featuredMedia.map.id)}
                >
                  <FloorMapIcon />
                </div>
              )}

              {featuredMedia.contact && (
                <div
                  className="icons_icon"
                  onClick={() => handleUpdateRoute(featuredMedia.contact.id)}
                >
                  <ContactFormIcon />
                </div>
              )}

              {isShowAudioIcon && (
                <AudioIcon activeColor={color} className="icons_icon" />
              )}
              <div
                className="icons_icon"
                onClick={() => {
                  toggleRotate();
                }}
              >
                <AutoRotateIcon
                  shouldRotate={shouldRotate}
                  activeColor={color}
                />
              </div>

              {enableGyroscope && (
                <div className="icons_icon" onClick={handleSwitchControlMode}>
                  <GyroscopeIcon activeColor={color} />
                </div>
              )}

              {isShowMapFloorPlan && (
                <>
                  <div
                    className="icons_icon position-relative"
                    onClick={() => setIsActiveMap(!isActiveMap)}
                  >
                    <MapFloorPlanIcon active={isActiveMap} />
                  </div>
                  <div
                    className={`mapfloorplan_container ${
                      isActiveMap ? 'open' : ''
                    }`}
                  >
                    {mapShowed && (
                      <>
                        {floorMaps.length > 1 && (
                          <div className="map_header">
                            <div
                              className="map_dropdown"
                              onClick={() => setOpenDropDown(!openDropDown)}
                            >
                              <span>
                                {limitLetters(mapShowed.image.name, 15)}
                              </span>
                              <i className="map_arrow down"></i>
                              {openDropDown && (
                                <div
                                  className={`map_dropdown_options ${
                                    openDropDown ? 'show' : ''
                                  }`}
                                >
                                  <ul className="dropdown_items">
                                    {floorMaps?.map((item, index) => (
                                      <li
                                        onClick={() => handleSelectMap(item)}
                                        key={index}
                                      >
                                        {limitLetters(item.image.name, 10)}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                            <div
                              className="icon"
                              onClick={() => handleUpdateRoute(mediaMap2D.id)}
                            >
                              <ExpandIcon />
                            </div>
                          </div>
                        )}
                        <div className="map_image">
                          <img
                            src={mapShowed.image.url}
                            alt={mapShowed.image.name}
                          />
                          {mapShowed.objects.map((viewpoint) => {
                            const currentPoint =
                              viewpoint.id === currentPano._id;
                            const [left, top] = viewpoint.coordinates;
                            const leftInPercent = left * 100;
                            const topInPercent = top * 100;
                            const currentScene =
                              scenes.find((s) => s._id === viewpoint.id) || {};

                            return (
                              <React.Fragment key={viewpoint.id}>
                                <div
                                  className={`viewpoint-container ${
                                    currentPoint
                                      ? 'current-viewpoint'
                                      : 'viewpoint'
                                  } `}
                                  style={{
                                    left: `${leftInPercent}%`,
                                    top: `${topInPercent}%`,
                                  }}
                                  onClick={() =>
                                    onSelect(
                                      currentScene.groupId,
                                      currentScene.id
                                    )
                                  }
                                ></div>
                                <p
                                  className="scene-title"
                                  style={{
                                    left: `${leftInPercent}%`,
                                    top: `${topInPercent}%`,
                                  }}
                                >
                                  {currentScene.title}
                                </p>
                                <div
                                  className="circular-sector"
                                  style={{
                                    display: `${
                                      currentPoint ? 'block' : 'none'
                                    }`,
                                    left: `${leftInPercent}%`,
                                    top: `${topInPercent}%`,
                                  }}
                                >
                                  {/* <MinorSector {...props} /> */}
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>

                        <div
                          className="mapfloorplan_close"
                          onClick={() => setIsActiveMap(false)}
                        >
                          <button className="mapfloorplan_close-btn">
                            &times;
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              {isAquaStyle && (
                <div className="tour-icons">
                  <div
                    className="icon-share"
                    onClick={() => handleUpdateRoute(configs.socialRoute)}
                  >
                    <ShareIcon color={color} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {!isMenuGallery && !isSchoolLayout && !isAquaStyle && !isPrimeStyle && (
          <>
            <div className="menuV2-mobile--top">
              <div className="menuV2-mobile--top_line"></div>
            </div>
            <div
              className="menuV2-mobile--header "
              onClick={() => toggleMenu(!isNavOpen)}
            >
              <div className="menuV2-mobile--header_icon">
                <PinIcon color={color} />
              </div>
              <div className="menuV2-mobile--header_titles">
                <div
                  className="menuV2-mobile--header_titles_title"
                  style={{ color: color }}
                >
                  {currentPano ? currentPano.title : null}
                </div>
                <p className="menuV2-mobile--header_titles_label">
                  {subTitle ?? null}
                </p>
              </div>
            </div>
          </>
        )}
        <PanoMenu
          isOpen={isNavOpen}
          menu={menuList}
          location={menuPosition}
          onClose={() => toggleMenu(false)}
          onSelect={menuOpenScene}
          color={color}
        />
      </div>
      {isMenuGallery && (
        <GalleryMenu
          scenes={scenes}
          mobile={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
        />
      )}

      {isSchoolLayout && (
        <SchoolLayoutMenu
          menuList={menuList}
          scenes={scenes}
          mobile={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
          isSchoolLayout={isSchoolLayout}
        />
      )}

      {isAquaStyle && (
        <AACorporationLayout
          menuList={menuList}
          scenes={scenes}
          desktop={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
          isSchoolLayout={isSchoolLayout}
          tourLogo={props.tour.logoURL}
        />
      )}

      {isPrimeStyle && (
        <KhaiHoanPrimeLayout
          menuList={menuList}
          scenes={scenes}
          desktop={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
          isSchoolLayout={isSchoolLayout}
          tourLogo={props.tour.logoURL}
          isPrimeStyle={isPrimeStyle}
          cameraParams={cameraParams}
          sceneRotation={sceneRotation}
        />
      )}

      {isPrimeStyle && isMobile && (
        <MapfloorplanModal
          isOpen={isOpenMapfloorplanModal}
          close={() => setIsOpenMapfloorplanModal(false)}
          setIsActiveMap={setIsActiveMap}
          isShowMapFloorPlan={isShowMapFloorPlan}
          isActiveMap={isActiveMap}
          mapShowed={mapShowed}
          floorMaps={floorMaps}
          setOpenDropDown={setOpenDropDown}
          openDropDown={openDropDown}
          handleSelectMap={handleSelectMap}
          handleUpdateRoute={handleUpdateRoute}
          mediaMap2D={mediaMap2D}
          currentPano={currentPano}
          scenes={scenes}
          onSelect={onSelect}
          isPrimeStyle={isPrimeStyle}
        />
      )}
    </>
  );
};

export default MenuForMobile;
