import React from 'react';

const CheckBox = (props) => {
  const bgColor = props.bgColor || 'white';
  const color = props.color || '#447594';
  return (
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
      <g>
        <ellipse
          fill={bgColor}
          cx="250"
          cy="250"
          rx="245"
          ry="245"
          strokeWidth="10"
          stroke={color}
        />
        <path
          fill={color}
          d="m156.189188,312.046662l-0.425965,74.583859l199.351765,0l0,-303.2873l-74.969894,-0.851931l0,229.169336l-123.955905,0.386035z"
          transform="rotate(45 255.43911743164074,234.5608978271484)"
          strokeWidth="0"
        />
      </g>
    </svg>
  );
};

export default CheckBox;
