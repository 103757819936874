import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import configs from './configs';
import { getCookie } from 'utils/trackingHelper';

const gaTrackerNames = ['Vizion', 'Customer'];

let hasGATracking = false;

export const init = (gaTrackingId, fbPixelId) => {
  // Google Analytics & Google Tags
  const trackingGroup = [];
  if (configs.gaTrackingId) {
    trackingGroup.push({
      trackingId: configs.gaTrackingId,
      gaOptions: {
        name: gaTrackerNames[0],
      },
    });
    hasGATracking = true;
  }
  if (gaTrackingId) {
    trackingGroup.push({
      trackingId: gaTrackingId,
      gaOptions: {
        name: gaTrackerNames[1],
      },
    });
    hasGATracking = true;
  }
  if (hasGATracking) {
    ReactGA.initialize(trackingGroup, {
      debug: false,
      // debug: !configs.prod,
      titleCase: false,
      alwaysSendToDefaultTracker: false,
    });
  }

  const tourId = window.data?.tour?.id;
  if (tourId) {
    trackPageView(`/${tourId}`);
    trackTourView(tourId);
  }
  trackPageView(window.location.pathname + window.location.search);

  if (fbPixelId) {
    window.logMessage('fbPixelId', fbPixelId);
    // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const advancedMatching = {};
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(fbPixelId, advancedMatching, options);
    ReactPixel.pageView();
  }
};

export const trackPageView = (page) => {
  if (hasGATracking) {
    // ReactGA.event('page_view', {
    //   page_location: page,
    //   user_agent: navigator.userAgent,
    // });
  }
};

export const trackTourView = (tourId) => {
  if (hasGATracking) {
    // ReactGA.event(`${EVENT_CATEGORY.TOUR}.${tourId}`, { item_id: tourId });
  }
};

export const trackEvent = (eventData) => {
  if (hasGATracking) {
    const { action, category, targetId } = eventData;
    let eventName = category;
    if (category === EVENT_CATEGORY.SCENE) {
      eventName += `.${targetId}.${action}`;
    }
    if (category === EVENT_CATEGORY.TOUR_MEDIA) {
      const tourId = window.data?.tour?.id;
      // eslint-disable-next-line
      eventName += `.${tourId}.${targetId}.${action}`;
    }
    // ReactGA.event(eventName, eventData);
  }
};

export const EVENT_CATEGORY = {
  TOUR: 'Tour',
  SCENE: 'Scene',
  MEDIA: 'Media',
  TOUR_MEDIA: 'TourMediaView', // new cat to track media associated to tour
  MAP: 'Map',
};

export const EVENT_TYPE = {
  SCENE: 'SceneView',
  SCENE_DEEPLINK: 'SceneView.DeepLink',
  SCENE_FROM_HOTSPOT: 'SceneView.HotspotClick',
  SCENE_FROM_MENU: 'SceneView.MenuClick',
  SCENE_FROM_MAP: 'SceneView.MapClick',
  MAP: 'MapView',
  MAP_DEEPLINK: 'MapView.DeepLink',
  HOTSPOT: 'MediaView',
  HOTSPOT_DEEPLINK: 'MediaView.DeepLink',
  HOTSPOT_FROM_HOTSPOT: 'MediaView.HotspotClick',
};

export const trackEventByType = (type, id) => {
  const eventData = {
    action: type,
  };
  if (id) {
    eventData.targetId = id;
    eventData.item_id = id;
  }
  if (
    type === EVENT_TYPE.SCENE ||
    type === EVENT_TYPE.SCENE_DEEPLINK ||
    type === EVENT_TYPE.SCENE_FROM_HOTSPOT ||
    type === EVENT_TYPE.SCENE_FROM_MENU ||
    type === EVENT_TYPE.SCENE_FROM_MAP
  ) {
    eventData.category = EVENT_CATEGORY.SCENE;
    eventData.label = `View Scene: ${id || ''}`;
  }
  if (
    type === EVENT_TYPE.HOTSPOT ||
    type === EVENT_TYPE.HOTSPOT_DEEPLINK ||
    type === EVENT_TYPE.HOTSPOT_FROM_HOTSPOT
  ) {
    eventData.category = EVENT_CATEGORY.TOUR_MEDIA;
    eventData.label = `View Media: ${id || ''}`;
  }
  if (type === EVENT_TYPE.MAP || type === EVENT_TYPE.MAP_DEEPLINK) {
    eventData.category = EVENT_CATEGORY.MAP;
    eventData.label = `Viewing Map`;
  }
  trackEvent(eventData);
};

export const initTracking = (gaTrackingId, trackDeepLink, fbPixelId) => {
  init(gaTrackingId, fbPixelId);
  if (!trackDeepLink) return;
  const { scene, hotspot } = trackDeepLink;
  if (hotspot) {
    if (hotspot === configs.mapRoute) {
      trackEventByType(EVENT_TYPE.MAP_DEEPLINK, null);
    } else {
      const mediaId = getMediaIdByFriendlyName(hotspot);
      trackEventByType(EVENT_TYPE.HOTSPOT_DEEPLINK, mediaId);
    }
  } else {
    const sceneId = getSceneIdByFriendlyName(scene);
    trackEventByType(EVENT_TYPE.SCENE_DEEPLINK, sceneId);
  }
};

const getSceneIdByFriendlyName = (name) => {
  const scenes = window.data?.json.scenes || [];
  return scenes.find(({ id }) => id === name)?._id || 'UNKNOWN';
};

const getMediaIdByFriendlyName = (name) => {
  const media = window.data?.json.media || [];
  return media.find(({ id }) => id === name)?._id || 'UNKNOWN';
};

export const initGtag = () => {
  const referrer = getCookie('referrer');
  const referrerId = referrer ? decodeURIComponent(referrer) : '';

  window.trackingTourData = {};
  window.trackingSceneData = {};
  window.referrer_id = referrerId;
  window.isFirst = true;
  window.tourCount = 1;

  if (!window.gtag) {
    function gtag() {
      // console.log(arguments);
    }
    window.gtag = gtag;
  }
};

export const sendGtagEvent = (event, data) => {
  // window.gtag('event', event, {
  //   ...data,
  // });
};

export const trackEventByGtag = (eventName, eventData) => {
  sendGtagEvent(eventName, eventData);
};

export const trackSessionStart = (data) => {
  sendGtagEvent('session_start', data);
};

export const countTimeCTACLick = (data) => {
  sendGtagEvent('cta_click', data);
};

export const trackTourByGtag = (data) => {
  sendGtagEvent('tour_view', data);
};

export const trackTimeOnTour = (data) => {
  return setInterval(() => {
    sendGtagEvent('tour_timer_15s', data);
  }, 3000);
};

export const trackSceneView = (data) => {
  sendGtagEvent('scene_view', data);
};

export const trackTimeOnScene = (data) => {
  return setInterval(() => {
    sendGtagEvent('scene_timer_15s', data);
  }, 3000);
};

export const trackUserEngagement = (data) => {
  sendGtagEvent('user_engagement', data);
};

export const trackUserEngagementSingle = (data) => {
  sendGtagEvent('user_engagement_single', data);
};

export const trackViewOfUser = (data) => {
  sendGtagEvent('scene_lookat', data);
};

export const trackTourLoadEnd = (data) => {
  sendGtagEvent('tour_load_end', data);
};

export const trackUserBeginEngagement = (data) => {
  sendGtagEvent('user_begin_engagement', data);
};

export const trackTourContentVisible = (data) => {
  sendGtagEvent('tour_content_visible', data);
};

export const trackTourContentOpen = (data) => {
  sendGtagEvent('tour_content_open', data);
};
