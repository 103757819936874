import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setViewMode } from 'store/actions';

const HidenUiByQueryParams = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const { viewMode } = useSelector((state) => state);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const modeValue = query.get('mode');
    if (modeValue && viewMode !== modeValue) dispatch(setViewMode(modeValue));
    if (viewMode && history.action !== 'POP') history.push('?mode=' + viewMode);
  }, [dispatch, history, search, viewMode]);
  return <></>;
};
export default HidenUiByQueryParams;
