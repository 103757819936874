import React from 'react';
import './pulse.scss';

const Pulse = () => (
  <p className="hs-icon-pulse">
    <span className="pulse">&nbsp;</span>
  </p>
);

export default Pulse;
