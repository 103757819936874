import React from 'react';
import HOTSPOT_TYPE from '../../../consts/hotspotType.const';
import SceneHotspotIcon from './scene';
import MediaHotspotIcon from './media';

const HotspotIcon = (props) => {
  const { type, scene, media, index, tour, isActiveIcon } = props;
  const Icon =
    type === HOTSPOT_TYPE.SCENE ? SceneHotspotIcon : MediaHotspotIcon;
  const data = type === HOTSPOT_TYPE.SCENE ? { ...scene } : { ...media };

  return (
    <Icon
      {...props}
      data={data}
      index={index}
      tour={tour}
      isActiveIcon={isActiveIcon}
    />
  );
};

export default HotspotIcon;
