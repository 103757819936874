import React from 'react';

export const PinIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="32"
      viewBox="0 0 19 32"
    >
      <g id="icon-pin" transform="translate(0)">
        <path
          id="Path_1541"
          data-name="Path 1541"
          d="M69.789,19a9.5,9.5,0,1,1,9.5-9.5,9.511,9.511,0,0,1-9.5,9.5m0-16a6.5,6.5,0,1,0,6.5,6.5,6.508,6.508,0,0,0-6.5-6.5"
          transform="translate(-60.289)"
          fill={color}
        />
        <path
          id="Path_1542"
          data-name="Path 1542"
          d="M70.289,32a1.5,1.5,0,0,1-1.5-1.5V18.255a1.5,1.5,0,0,1,3,0V30.5a1.5,1.5,0,0,1-1.5,1.5"
          transform="translate(-60.289)"
          fill={color}
        />
      </g>
    </svg>
  );
};
