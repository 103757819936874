import { SOCICAL_MEDIA } from 'consts/socialMediaData';

/**
 * Ensure old data will still get data from customer
 * @param {object} tour Tour Data
 * @param {object} json Json Data
 * @returns {object} Data tour combined
 */
export const combineTour = (tour, { customer }) => {
  const objectCustomerTour = {};
  Object.keys(customer).map(
    (item) => (objectCustomerTour[item.toLowerCase()] = customer[item])
  );
  SOCICAL_MEDIA.forEach((item) => {
    if (!tour[item]) {
      tour[item] = objectCustomerTour[item.toLowerCase()];
    }
  });
  return tour;
};
