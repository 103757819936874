import React from 'react';
import './sceneIndoorHotspot.scss';

const sceneIndoorHotspot = ({ scene, onClick }) => {
  return (
    <div className="khp-indoor-hotspot" onClick={onClick}>
      <div className="khp-indoor-hotspot__pulse">&nbsp;</div>
    </div>
  );
};

export default sceneIndoorHotspot;
