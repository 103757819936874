import React, { useState } from 'react';
import AquaModal from 'components/AquaModal';
import { AquaLocationIcon, AquaVideoIcon } from 'components/icons/AquaIcons';
import HoveringPlate from 'components/HoveringPlate';

const MAPIFRAME =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15676.26935678528!2d106.61836624145508!3d10.806154553909906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752be27d8b4f4d%3A0x92dcba2950430867!2sHCMC%20University%20of%20Industry%20and%20Trade!5e0!3m2!1sen!2s!4v1715332984041!5m2!1sen!2s';

const YOUTUBEIFRAME =
  'https://www.youtube.com/embed/f7dx1GgO7CI?si=UDpkw-aYukxDQXN3';

export default function AquaExtraIcons() {
  const [isOpen, setIsOpen] = useState(false);

  const [iframeObject, setIframeObject] = useState({});

  return (
    <>
      <HoveringPlate textHover={'Video'}>
        <div
          className={`icon icon-aquaStyle`}
          onClick={() => {
            setIsOpen(true);
            setIframeObject({ url: YOUTUBEIFRAME, type: 'youtube' });
          }}
        >
          <AquaVideoIcon />
        </div>
      </HoveringPlate>

      <HoveringPlate textHover={'Vị trí'}>
        <div
          className={`icon icon-aquaStyle`}
          onClick={() => {
            setIsOpen(true);
            setIframeObject({ url: MAPIFRAME, type: 'map' });
          }}
        >
          <AquaLocationIcon />
        </div>
      </HoveringPlate>

      <AquaModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        iframeObject={iframeObject}
      />
    </>
  );
}
