import React, { useMemo } from 'react';
import './sceneOutdoorHotspot.scss';

const SceneOutdoorHotspot = ({ scene, onClick }) => {
  const titles = useMemo(() => {
    if (scene.title) {
      const t = (scene.title || '').toLowerCase();
      if (t.toLowerCase().indexOf('sân tennis') === 0) {
        return t
          .split('&')
          .map((s) => s.replace('- ban đêm', '').replace('ban đêm', '').trim());
      }
      return [t].map((s) =>
        s.replace('- ban đêm', '').replace('ban đêm', '').trim()
      );
    }
    return [''];
  }, [scene]);

  return (
    <div className="khp-outdoor-hotspot" onClick={onClick}>
      <div className="khp-outdoor-hotspot__pulse">&nbsp;</div>
      <div className="khp-outdoor-hotspot__title">
        {titles.map((title) => (
          <p key={title} className="khp-outdoor-hotspot__title-text">
            {title}
          </p>
        ))}
        <div className="khp-outdoor-hotspot__thumb">
          <img src={scene.previewImgUrl} alt={titles[0]} />
        </div>
      </div>
    </div>
  );
};

export default SceneOutdoorHotspot;
