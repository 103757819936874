import React, { useMemo, useState } from 'react';

import './HoveringPlate.scss';

export default function HoveringPlate({ children, textHover, isPrimeStyle }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    setPosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const updatedText = useMemo(() => {
    if (!textHover) return '';

    return textHover
      .toLowerCase()
      .replace('- ban đêm', '')
      .replace('ban đêm', '')
      .trim();
  }, [textHover]);

  return (
    <div
      className="hover-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      {children}
      {showTooltip && updatedText && (
        <div
          className={`tooltip ${isPrimeStyle ? 'primeStyle' : ''}`}
          style={{ top: `${position.y}px`, left: `${position.x}px` }}
        >
          {updatedText === 'bbq riverside' ? 'BBQ Riverside' : updatedText}
        </div>
      )}
    </div>
  );
}
