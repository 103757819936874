import React from 'react';

export default function MoonIcon() {
  return (
    <svg
      width="17"
      height="18"
      xmlns="http://www.w3.org/2000/svg"
      overflow="hidden"
    >
      <g transform="translate(-372 -62)">
        <path
          d="M8.32292 2.21354C7.66771 1.89479 6.99479 1.68229 6.32188 1.59375 8.26979 3.52396 8.85417 6.55208 7.56146 9.15521 6.28646 11.7583 3.48854 13.1042 0.796875 12.7323 1.275 13.2104 1.85938 13.6354 2.49688 13.9542 5.7375 15.5656 9.66875 14.2375 11.2625 10.9969 12.8562 7.75625 11.5635 3.84271 8.32292 2.21354Z"
          fill="#105260"
          transform="matrix(1 0 0 1.05882 372 62)"
        />
        <path
          d="M15.3885 9.65104C15.3531 9.59792 15.3531 9.52708 15.3885 9.47396L15.725 8.97812C15.7604 8.90729 15.7604 8.83646 15.725 8.78333 15.6896 8.73021 15.601 8.69479 15.5479 8.7125L14.999 8.85417C14.9281 8.87187 14.8573 8.83646 14.8219 8.78333L14.4854 8.2875C14.4323 8.21667 14.3615 8.19896 14.2906 8.21667 14.2198 8.23438 14.1667 8.30521 14.1667 8.37604L14.1313 8.97812C14.1313 9.04896 14.0781 9.10208 14.0073 9.1375L13.4583 9.26146C13.3875 9.27917 13.3344 9.33229 13.3344 9.42083 13.3344 9.49167 13.3698 9.5625 13.4406 9.58021L13.6708 9.70417 13.9719 9.86354C13.9896 9.88125 14.025 9.89896 14.0427 9.93437 14.0604 9.96979 14.0604 9.9875 14.0604 10.0229L14.0604 10.625C14.0604 10.6958 14.0958 10.7667 14.1667 10.7844 14.2375 10.8021 14.3083 10.7844 14.3615 10.7312L14.751 10.2531C14.8042 10.2 14.875 10.1823 14.9458 10.2L15.5302 10.4125C15.601 10.4302 15.6719 10.4125 15.725 10.3594 15.7781 10.3062 15.7781 10.2177 15.7427 10.1646L15.3885 9.65104Z"
          fill="#105260"
          transform="matrix(1 0 0 1.05882 372 62)"
        />
        <path
          d="M13.7063 5.24167 14.1844 4.37396C14.2375 4.26771 14.3438 4.21458 14.4677 4.23229L15.4417 4.39167C15.5656 4.40937 15.6719 4.35625 15.725 4.25 15.7781 4.14375 15.7604 4.00208 15.6896 3.93125L15.0167 3.20521C14.9458 3.13438 14.9281 3.01042 14.9635 2.90417L15.3354 2.00104C15.3708 1.89479 15.3531 1.77083 15.2823 1.7 15.2115 1.62917 15.0698 1.59375 14.9812 1.64688L14.149 2.05417C14.0427 2.10729 13.9187 2.07187 13.8479 2.00104L13.175 1.32812C13.0865 1.23958 12.9448 1.22187 12.8385 1.25729 12.7323 1.31042 12.6792 1.43438 12.6792 1.54062L12.8031 2.47917C12.8208 2.60312 12.7677 2.70937 12.6615 2.7625L11.8292 3.16979C11.7229 3.22292 11.6698 3.32917 11.6875 3.45312 11.7052 3.55938 11.7937 3.66563 11.9 3.68333L12.3073 3.80729 12.8385 3.96667C12.8917 3.98438 12.9271 4.01979 12.9802 4.05521 13.0156 4.09062 13.0333 4.14375 13.051 4.19688L13.2104 5.17083C13.2281 5.29479 13.3167 5.38333 13.4406 5.40104 13.5646 5.41875 13.6531 5.34792 13.7063 5.24167Z"
          fill="#105260"
          transform="matrix(1 0 0 1.05882 372 62)"
        />
      </g>
    </svg>
  );
}
