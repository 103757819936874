import React, { useEffect, useRef, useMemo } from 'react';
import { useThree, useFrame, extend } from '@react-three/fiber';
import { useSelector } from 'react-redux';
import * as THREE from 'three';
import {
  Lensflare,
  LensflareElement,
} from 'three/examples/jsm/objects/Lensflare';
import { loadTextures } from 'utils/textureHelper';
import {
  get2DScreenPosition,
  distanceBetween2dCoordinates,
} from 'utils/positionHelper';
import { getCenterScreen } from 'containers/world/utils';
import configs from 'configs';
import { updateVector3OrPointByRotationXZ } from '../utils';

const MAX_SUN_SIZE = 300;
const MIN_SUN_SIZE = 90;

extend({ Lensflare, LensflareElement });

const getLightPosition = (position) => {
  const aspect = 0.99;
  const [x, y, z] = position;
  return {
    x: x * aspect,
    y: y * aspect,
    z: z * aspect,
  };
};

const getWindowMinSize = () =>
  // get min width from center to border
  (window.innerWidth > window.innerHeight
    ? window.innerHeight
    : window.innerWidth) / 2;

const LensflareComponent = ({ position }) => {
  const lightRef = useRef();
  const prevRotation = useSelector((state) => state.prevRotation);
  const { camera } = useThree();
  const sunRef = useRef();
  const [x, y, z] = useMemo(() => {
    const lightPos = Object.values(getLightPosition(position));
    return updateVector3OrPointByRotationXZ(lightPos, prevRotation, false);
  }, [position, prevRotation]);

  const loadLenflare = () => {
    const lensflareImgs = [
      configs.baseUrl +
        '/assets/images/lensflare/sunflare/sunflare-center-blur.png',
      configs.baseUrl + '/assets/images/lensflare/sunflare/flare-red.png',
      configs.baseUrl + '/assets/images/lensflare/sunflare/flare-yellow.png',
      configs.baseUrl + '/assets/images/lensflare/sunflare/flare-green.png',
      configs.baseUrl + '/assets/images/lensflare/sunflare/flare-blue.png',
      configs.baseUrl + '/assets/images/lensflare/sunflare/rainbow-blur.png',
    ];

    loadTextures(lensflareImgs, 2).then((textures) => {
      const [
        flareSun,
        flareRed,
        flareYellow,
        flareGreen,
        flareBlue,
        flareRainB,
      ] = textures;

      const lensflare = new Lensflare();
      const color = new THREE.Color(0xffffff);
      sunRef.current = new LensflareElement(flareSun, MAX_SUN_SIZE, 0, color);
      lensflare.addElement(sunRef.current);
      lensflare.addElement(new LensflareElement(flareRed, 40, 0.5));
      lensflare.addElement(new LensflareElement(flareYellow, 40, 0.515));
      lensflare.addElement(new LensflareElement(flareGreen, 40, 0.53));
      lensflare.addElement(new LensflareElement(flareBlue, 50, 0.6));
      lensflare.addElement(new LensflareElement(flareRainB, 1500, 0.4));
      if (lightRef.current) {
        lightRef.current.add(lensflare);
      }
    });
  };

  const setSunSize = (size) => {
    if (sunRef.current) {
      sunRef.current.size = size;
    }
  };

  useEffect(() => {
    loadLenflare();
    window.logMessage('lensflare did mount');
  }, []);

  useFrame(() => {
    const xyLocation = get2DScreenPosition(
      new THREE.Vector3(x, y, z),
      camera,
      window.innerWidth,
      window.innerHeight
    );

    if (!xyLocation || !xyLocation.visible) {
      setSunSize(0);
    } else {
      const maxVisibleDistance = getWindowMinSize(),
        centerArea = 50,
        screenCenter = getCenterScreen();
      const distance = distanceBetween2dCoordinates(xyLocation, screenCenter);
      if (distance <= maxVisibleDistance) {
        if (distance <= centerArea) {
          setSunSize(MAX_SUN_SIZE);
        } else {
          const newSize = Math.max(
            MIN_SUN_SIZE,
            (1.0 -
              (distance - centerArea) / (maxVisibleDistance - centerArea)) *
              MAX_SUN_SIZE
          );
          setSunSize(newSize);
        }
      }
    }
  });

  return (
    <pointLight
      ref={lightRef}
      color={0xffffff}
      intensity={1}
      distance={2000}
      decay={2}
      position={[x, y, z]}
    />
  );
};

export default LensflareComponent;
