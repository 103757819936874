import React from 'react';

function Tip({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="124"
      height="125"
      viewBox="0 0 124 125"
    >
      <defs>
        {/* <style>.a,.c,.d{fill:none;stroke:#000;strokeWidth:2px;}.b{clip-path:url(#a);}.c,.d{stroke-linecap:round;stroke-miterlimit:10;}.c{stroke-linejoin:round;}</style> */}
        <clipPath id="a">
          <rect
            style={{
              fill: 'none',
              stroke: '#000',
              strokeWidth: '2px',
            }}
            width="124"
            height="125"
            transform="translate(0.298)"
          />
        </clipPath>
      </defs>
      <g style={{ clipPath: 'url(#a)' }} transform="translate(-0.298)">
        <g transform="translate(31.114 30.292)">
          <g transform="translate(8.452 8.802)">
            <path
              style={{
                fill: 'none',
                stroke: `${color}`,
                strokeWidth: '2px',
                strokeLinecap: 'round',
                strokeMiterlimit: 10,
                strokeLinejoin: 'round',
              }}
              d="M-80.094,1581.032a7.5,7.5,0,0,0-7.068-10h.005a7.5,7.5,0,0,0-7.068,10l7.068,13.673Z"
              transform="translate(94.66 -1571.034)"
            />
            <ellipse
              style={{
                fill: 'none',
                stroke: `${color}`,
                strokeWidth: '2px',
                strokeLinecap: 'round',
                strokeMiterlimit: 10,
                strokeLinejoin: 'round',
              }}
              cx="2.863"
              cy="2.863"
              rx="2.863"
              ry="2.863"
              transform="translate(4.637 4.719)"
            />
          </g>
          <g transform="translate(8.452 35.569)">
            <path
              style={{
                fill: 'none',
                stroke: `${color}`,
                strokeWidth: '2px',
                strokeLinecap: 'round',
                strokeMiterlimit: 10,
                strokeLinejoin: 'round',
              }}
              d="M-80.094,1816.2a7.5,7.5,0,0,0-7.068-10h.005a7.5,7.5,0,0,0-7.068,10l7.068,13.673Z"
              transform="translate(94.66 -1806.203)"
            />
            <ellipse
              style={{
                fill: 'none',
                stroke: `${color}`,
                strokeWidth: '2px',
                strokeLinecap: 'round',
                strokeMiterlimit: 10,
                strokeLinejoin: 'round',
              }}
              cx="2.863"
              cy="2.863"
              rx="2.863"
              ry="2.863"
              transform="translate(4.637 3.929)"
            />
          </g>
          <rect
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeWidth: '2px',
              strokeLinecap: 'round',
              strokeMiterlimit: 10,
              strokeLinejoin: 'round',
            }}
            width="63.151"
            height="63.204"
            rx="3"
            transform="translate(0)"
          />
          <line
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeWidth: '2px',
              strokeLinecap: 'round',
              strokeMiterlimit: 10,
            }}
            x2="23.333"
            transform="translate(29.015 15.286)"
          />
          <line
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeWidth: '2px',
              strokeLinecap: 'round',
              strokeMiterlimit: 10,
            }}
            x2="12.634"
            transform="translate(29.015 25.757)"
          />
          <line
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeWidth: '2px',
              strokeLinecap: 'round',
              strokeMiterlimit: 10,
            }}
            x2="23.333"
            transform="translate(29.015 42.168)"
          />
          <line
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeWidth: '2px',
              strokeLinecap: 'round',
              strokeMiterlimit: 10,
            }}
            x2="12.634"
            transform="translate(29.015 52.639)"
          />
        </g>
      </g>
    </svg>
  );
}

export default Tip;
