import React from 'react';

function iconMapFloorPlan(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 38 38"
    >
      <g fill="#fff" stroke="white" strokeWidth="1px" opacity="1">
        <circle stroke="none" cx="19" cy="19" r="19" />
        <circle fill="black" cx="19" cy="19" r="18" />
      </g>
      <g transform="translate(22500.072 1501.473)">
        <path
          d="M715.256,195.308l5.209,2.314,0-14.741-5.212-2.314Z"
          transform="translate(-23198.559 -1671.568)"
          fill="white"
        />
        <path
          d="M723.5,197.578l4.466-2.232V180.64l-4.466,2.232Z"
          transform="translate(-23200.109 -1671.581)"
          fill="white"
        />
        <path
          d="M707.01,197.622l5.209-2.314V180.568l-5.209,2.314Z"
          transform="translate(-23197.01 -1671.568)"
          fill="white"
        />
      </g>
    </svg>
  );
}

export default iconMapFloorPlan;
