import React from 'react';

import Google360Icon from 'assets/icons/google.png';

function iconGoogle360(props) {
  return (
    <div className="hotspot_icon">
      <img alt="video-icon" src={Google360Icon} style={{ width: '60%' }}></img>
    </div>
  );
}

export default iconGoogle360;
