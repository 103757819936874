import React, { useMemo } from 'react';

import { limitLetters } from 'utils/limitLetters';

import './style.scss';

const MenuItem = ({
  scenes,
  sceneGroup,
  onSelect,
  selectedCard,
  handleSelect,
  activeColor,
}) => {
  const { _id } = sceneGroup;

  const defaultScene = useMemo(
    () => scenes.find((scene) => scene._id === _id),
    [_id, scenes]
  );

  const handleSwitchPano = () => {
    onSelect(sceneGroup.groupId, defaultScene.id);
    handleSelect();
  };

  const isSelected = useMemo(() => selectedCard === _id, [_id, selectedCard]);

  return (
    <div
      className="menu-card"
      onClick={handleSwitchPano}
      style={{ border: isSelected ? `2px solid ${activeColor}` : '' }}
    >
      <div className="menu-image">
        <img src={defaultScene.previewImgUrl} alt={defaultScene.title} />
        <div className="menu-image__scene-title">
          <p>{limitLetters(defaultScene.title, 15)}</p>
        </div>
      </div>
      {/* <div className="menu-title">{title}</div> */}
    </div>
  );
};

export default MenuItem;
