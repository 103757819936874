import { UniformsUtils, ShaderMaterial } from 'three';

const CustomShader = {
  vertex: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`,
  fragment: `
    varying vec2 vUv;
    uniform sampler2D texture1;
    uniform float transparentOpacity;
    
    void main() {
      vec4 _texture = texture2D(texture1, vUv);
      vec3 _color = _texture.rgb;
      float alpha = _texture.a;
    
      // Apply transparency based on alpha value
      if (alpha < 0.1) {  // You can adjust the threshold as needed
        discard;
      }
    
      gl_FragColor = vec4(_color, alpha);
    }
  `,
};

export default class TransparentShader extends ShaderMaterial {
  constructor(texture) {
    const uniforms = UniformsUtils.merge([
      {
        texture1: { type: 't', value: texture },
        transparentOpacity: { type: 'f', value: 1.0 },
      },
    ]);

    super({
      uniforms: uniforms,
      vertexShader: CustomShader.vertex,
      fragmentShader: CustomShader.fragment,
    });
  }

  get texture1() {
    return this.uniforms.texture1.value;
  }
  set texture1(v) {
    this.uniforms.texture1.value = v;
  }
  get transparentOpacity() {
    return this.uniforms.transparentOpacity.value;
  }
  set transparentOpacity(v) {
    this.uniforms.transparentOpacity.value = v;
  }
}
