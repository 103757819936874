import React from 'react';

import { useTranslation } from 'react-i18next';

function IconClose(props) {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="33"
      viewBox="0 0 90 33"
    >
      <g transform="translate(-897.815 -117.763)">
        <rect
          style={{ fill: '#fff', opacity: 0.8 }}
          width="90"
          height="33"
          rx="16.5"
          transform="translate(897.815 117.763)"
        />
        <text
          style={{
            fontSize: '18px',
            fontFamily: 'AdventPro-Light, Advent Pro',
            fontWeight: 300,
          }}
          transform="translate(952.814 140.763)"
        >
          <tspan x="-25.613" y="0">
            {t('close')}
          </tspan>
        </text>
        <path
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'round',
          }}
          d="M4889.486,1285.453l10.216,10.216"
          transform="translate(-3975.033 -1156.189)"
        />
        <path
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'round',
          }}
          d="M0,0,10.216,10.216"
          transform="translate(924.67 129.263) rotate(90)"
        />
      </g>
    </svg>
  );
}

export default IconClose;
