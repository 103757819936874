import React from 'react';

import InfoIcon from 'assets/icons/info.png';

const Icon = (props) => {
  return (
    <div className="hotspot_icon">
      <img alt="info-icon" src={InfoIcon} style={{ width: '7px' }}></img>
    </div>
  );
};

export default Icon;
