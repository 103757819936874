import React from 'react';

import { connect } from 'react-redux';

import configs from 'configs';
import { initTracking, trackTourLoadEnd } from 'gaTracking';
import { loadTourJson } from 'utils/apiHelper';
import { CONTROLS } from 'consts';

import {
  goToScene,
  setAudioAvailable,
  setPanoData,
  setTourData,
  toggleLanding,
  toggleMenu,
  setTourAudioAvailabe,
  setTourError,
  setAutoRotate,
  updateWorldParams,
  setPanning,
  setDateLight,
  setApartmentPopup,
} from 'store/actions';

import DomErrorBoundary from 'components/errors/domError';
import PanoErrorBoundary from 'components/errors/panoError';
import ResumeExperience from 'containers/ResumeExperience';
import LoadingImage from 'components/LoadingImage';
import HidenUiByQueryParams from 'components/HidenUiByQueryParams';

import { INTRO_STEP } from '../landing/intro';
import RouteHandlers from './routes';
import World2D from './world2d';
import World3D from './world3d';
// import GoogleAnalyticsTrackingComponent from './GoogleAnalyticsTrackingComponent';
import PopupPasscode from './popupPasscode';

import './style.scss';
import AutoPlayModeByQueryParams from 'components/AutoPlayModeByQueryParams';
import { formatScenes } from './utils';
import {
  getOppositeScene,
  isDayOrNight,
  isDayScene,
  isNightScene,
} from 'components/KhaiHoanPrimeLayout/dayNight.const';

const CONFIG_DELAY = 10000;

class World extends React.Component {
  state = {
    featuredHotspot: {},
    initiating: true,
    json: null,
    jsonInvalid: false,
    tour: null,
    currentPano: null,
    isPanning: false,
    sdLoaded: false,
    hdLoaded: false,
    spinner: true,
    showSpinner: true,
    showIntro: false,
    introStatus: 'in',
    defaultScene: null,
    hotspotHovering: false,
    popupOpen: false,
    shouldRotate: true,
    control: CONTROLS.ORBIT,
    isFinalIntroSlide: false,
    isFirstTime: true,
    showPasscode: false,
    enteredPasscode: true,
    cameraParams: {},
    sceneRotation: {},
  };
  isPanning = false;
  panCounter = 0;
  disableRotate = false;
  panTimer = null;
  worldEl = null;
  trackDeepLink = null;

  componentDidMount() {
    const { tourId, sceneId, hotspotId } = this.props.match.params;
    const tourIdData = tourId || 'khai-hoan-prime';
    if (sceneId || hotspotId) {
      this.trackDeepLink = {
        tour: tourIdData,
        scene: sceneId,
        hotspot: hotspotId,
      };
    }
    this.loadData(tourIdData);
  }

  componentDidUpdate(preProps, preState) {
    const { groupId, sceneId, hotspotId } = this.props.worldParams;
    if (sceneId && sceneId !== preProps.worldParams.sceneId) {
      this.selectSceneById(groupId, sceneId);
    }
    const { currentScene } = this.props;
    if (
      currentScene.id &&
      (preProps.currentScene.id !== currentScene.id ||
        preProps.currentScene.groupId !== currentScene.groupId)
    ) {
      this.selectSceneById(currentScene.groupId, currentScene.id);
    }
    if (!hotspotId && !preState.sdLoaded && this.state.sdLoaded) {
      this.toggleIntro(true);
    }
    if (hotspotId && preState.showSpinner && !this.state.showSpinner) {
      this.setState({ introStatus: 'out' });
    }
    if (
      preState.hotspotHovering !== this.state.hotspotHovering ||
      preProps.autoRotate !== this.props.autoRotate
    ) {
      this.handleRotate();
    }
  }

  setHotspotHovering = (isHovering = false) => {
    this.setState({ hotspotHovering: isHovering });
  };

  handleRotate = () => {
    const { hotspotHovering } = this.state;
    const { autoRotate } = this.props;
    if (hotspotHovering) {
      if (autoRotate) {
        this.setState({ shouldRotate: false });
        this.disableRotate = true;
      }
    } else {
      this.setState({ shouldRotate: autoRotate });
    }
  };

  getFeaturedMedia = (featuredId, mediaList) => {
    if (featuredId) {
      return mediaList.find((m) => m._id === featuredId);
    }
    return null;
  };

  dataArrived = (json) => {
    // window.logMessage('json', json);
    if (!json.cubeMap) {
      const { tour } = json;
      initTracking(
        tour.googleAnalyticTrackingId,
        this.trackDeepLink,
        tour.facebookPixelID
      );
      if (tour.audioAvailable) {
        this.props.setAudioAvailable(true);
      }
      const requirePasscode = !!tour.passcode;
      const passcodeData = {
        showPasscode: requirePasscode,
        enteredPasscode: !requirePasscode,
      };
      const autoRotate = Boolean(tour && tour.autoRotate) || false;
      const defaultScene = this.getDefaultScene(json);
      let featuredHotspot = {};
      if (json.featured && json.featured.mediaId) {
        const featuredMedia = this.getFeaturedMedia(
          json.featured.mediaId,
          json.media
        );
        featuredHotspot = featuredMedia
          ? {
              id: featuredMedia.friendlyName,
              _id: featuredMedia._id,
              media: featuredMedia,
            }
          : {};
      }
      this.setState(
        {
          json,
          tour,
          defaultScene,
          featuredHotspot,
          shouldRotate: autoRotate,
          ...passcodeData,
        },
        this.selectDefaultScene
      );
      this.props.setAutoRotate(autoRotate);
    } else {
      this.setState({ jsonInvalid: true });
    }
  };

  loadData = (tourId = null) => {
    loadTourJson(tourId).then(
      ({ json, tour }) => {
        if (json && json.status === 'INVALID_IP') {
          return this.props.setTourError({
            code: 'INVALID_IP',
            detail: tourId,
          });
        }

        if (tour && !tour.active) {
          return this.props.setTourError({
            code: 'TOUR_INACTIVE',
            detail: tourId,
          });
        }

        if (json) {
          // window.logMessage('Tour data:', tour, json);
          this.props.setPanoData(json);
          this.props.setTourData(tour);
          this.dataArrived(json);
        } else {
          this.props.setTourError({ code: 'NO_JSON', detail: tourId });
        }
      },
      (err) => {
        console.error('got error', err);
        this.props.setTourError({ code: 'NO_TOUR', detail: tourId });
      }
    );
  };

  getDefaultScene = ({ scenes, groups }) => {
    let defaultScene = scenes.find((p) => p.isDefault);
    if (!defaultScene) {
      defaultScene = scenes[0];
    }
    return defaultScene;
  };

  selectDefaultScene = () => {
    const { groupId, sceneId } = this.props.worldParams;
    const {
      defaultScene,
      json: { scenes, groups },
    } = this.state;
    let group = null;
    if (groupId) {
      group = groups.find((g) => g.id === groupId);
      if (!group) {
        group = groups.find((g) => g.isDefault) || groups[0];
      }
      if (group) {
        let scene = null;
        if (sceneId) {
          scene = scenes.find((s) => s.id === sceneId);
        }
        if (!scene) {
          scene = scenes.find((s) => s.groupId === group.id && s.isDefault);
        }
        if (!scene) {
          scene = scenes.find((s) => s.groupId === group.id);
        }
        if (scene) {
          return this.selectSceneById(scene.groupId, scene.id);
        }
      }
    }
    if (defaultScene) {
      return this.selectSceneById(defaultScene.groupId, defaultScene.id);
    }
    // group not found and there is no group
    this.selectSceneById(null); // which will redirect to 404
  };

  selectSceneById = (gId, sId, isForced = false, changeDateNight = false) => {
    if (!gId) {
      return this.props.setTourError({ code: 'NO_GROUP', detail: gId });
    }
    const { groups } = this.state.json;
    const group = groups.find((s) => s.id === gId);
    if (!group) {
      return this.props.setTourError({ code: 'NO_GROUP', detail: gId });
    }
    const { groupId, sceneId, hotspotId } = this.props.worldParams;
    if (gId !== groupId || sceneId !== sId) {
      const isDayOrNightScene = isDayOrNight(sId);
      if (isForced || !isDayOrNightScene) {
        // ko có j đặc biệt, như bình thường
        this.props.updateWorldParams({ groupId: gId, sceneId: sId });
        if (
          isDayOrNightScene &&
          changeDateNight &&
          this.props.dateLight !== isDayScene(sId)
        ) {
          this.props.setDateLight(isDayScene(sId));
          return;
        }
        if (!isDayOrNightScene && !this.props.dateLight) {
          this.props.setDateLight(true);
          return;
        }
        return;
      } else {
        // check day-night
        if (this.props.dateLight && isDayScene(sId)) {
          // current is day time
          // và target scene cũng là day time
          // ko có j đặc biệt, như bình thường
          this.props.updateWorldParams({ groupId: gId, sceneId: sId });
          return;
        }

        if (!this.props.dateLight && isNightScene(sId)) {
          // current is night time
          // và target scene cũng là night time
          // ko có j đặc biệt, như bình thường
          this.props.updateWorldParams({ groupId: gId, sceneId: sId });
          return;
        }
        // đang ngày mà đổi sang 1 scene đêm hoặc đang đêm mà đổi sang 1 scene ngày
        // => sai => tìm scene cùng thời điểm tương ứng
        let nextSceneId = getOppositeScene(sId);
        if (!nextSceneId) {
          // ko tìm được scene đối lập ngày đêm => chấp nhận & đổi state ngày đêm
          this.props.setDateLight(!this.props.dateLight);
          this.props.updateWorldParams({ groupId: gId, sceneId: sId });
          return;
        }
        // tìm được scene đối lập, và nó khác scene hiện tại
        if (nextSceneId !== sceneId) {
          this.props.updateWorldParams({ groupId: gId, sceneId: nextSceneId });
          return;
        }
        // tìm được scene đối lập lại chính là scene hiện tại
        if (nextSceneId === sceneId) {
          this.selectSceneById(gId, nextSceneId);
          return;
        }
      }
    } else {
      // groupId and sceneId did NOT change
      // need to select scene and check to open lightbox
      const { currentScene } = this.props;
      if (currentScene.id === sId && currentScene.groupId === gId) {
        this.loadSceneById(gId, sId);
      } else {
        this.props.goToScene(gId, sId);
      }
      if (hotspotId && hotspotId !== configs.mapRoute) {
        this.selectHotspot(hotspotId);
      }
    }
  };

  loadSceneById = (groupId, sceneId) => {
    const { scenes, media } = this.state.json;

    const newScenes = formatScenes(media, scenes);

    const scene =
      newScenes.find((p) => p.id === sceneId && p.groupId === groupId) || null;
    if (scene) {
      this.setScene(scene);
    } else {
      return this.props.setTourError({ code: 'NO_SCENE', detail: sceneId });
    }
  };

  setScene = (currentPano) => {
    const state = {
      currentPano,
    };
    if (this.state.initialing) {
      state.initiating = false;
    }
    this.setState(state);
  };

  selectHotspot = (hsId) => {
    if (!this.state.currentPano) {
      return setTimeout(() => this.selectHotspot(hsId), 500);
    }
    const { media } = this.state.json;
    const { worldParams } = this.props;
    const { groupId, sceneId, hotspotId } = worldParams;
    const foundFeatured = this.props.featuredHotspot.find((h) => h.id === hsId);
    if (foundFeatured) {
      if (hsId !== hotspotId) {
        this.props.updateWorldParams({ groupId, sceneId, hotspotId: hsId });
      } else {
        this.props.showHotspot && this.props.showHotspot(foundFeatured);
      }
      return;
    }

    const currentMedia = media.find((m) => m.id === hsId) || {};
    const hotspot = {
      id: currentMedia.friendlyName,
      _id: currentMedia._id,
      media: currentMedia,
    };

    if (!hotspot && sceneId) {
      this.props.updateWorldParams({ groupId, sceneId });
      this.toggleIntro(true);
      return;
    }
    if (hsId !== hotspotId) {
      this.props.updateWorldParams({ groupId, sceneId, hotspotId: hsId });
    }
  };

  toggleFeatured = (featureId) => {
    const { groupId, sceneId } = this.props.worldParams;
    const { featuredHotspot } = this.props;

    if (featureId) {
      const foundFeatured = featuredHotspot.find((h) => h.id === featureId);
      if (foundFeatured) {
        this.props.updateWorldParams({
          groupId,
          sceneId,
          hotspotId: foundFeatured.id,
        });
      }
    } else {
      this.props.updateWorldParams({ groupId, sceneId });
    }
  };

  toggleRotate = (isAutoRotate) => {
    if (isAutoRotate === undefined) {
      this.disableRotate = false;
      this.resetTimer();
    }
    this.props.setAutoRotate(
      isAutoRotate !== undefined ? isAutoRotate : !this.props.autoRotate
    );
  };

  onPointerClick = () => {
    if (this.props.isNavOpen) {
      this.props.toggleMenu(false);
    }
  };

  onPointerDown = () => {
    if (!this.isPanning) {
      this.isPanning = true;
      this.setState({ isPanning: true });
    }
  };

  onPointerMove = () => {
    if (this.isPanning) {
      if (!this.props.isPanning) this.props.setPanning(true);
      if (this.props.isNavOpen) {
        this.props.toggleMenu(false);
      }
      this.panCounter += 1;
      if (this.panCounter > 3) {
        if (this.props.autoRotate) {
          this.disableRotate = true;
          this.toggleRotate(false);
        }
        if (this.disableRotate) {
          this.resetTimer();
        }
      }
    }
  };

  onPointerUp = () => {
    if (this.isPanning) {
      if (this.props.isPanning) this.props.setPanning(false);
      this.isPanning = false;
      this.setState({ isPanning: false });
      this.panCounter = 0;
      if (this.disableRotate && !this.panTimer) {
        this.panTimer = setTimeout(() => {
          this.toggleRotate(true);
          this.disableRotate = false;
          this.panTimer = null;
        }, CONFIG_DELAY);
      }
    }
  };

  resetTimer = () => {
    if (this.panTimer) {
      clearTimeout(this.panTimer);
      this.panTimer = null;
    }
  };

  pointerEvents = {
    onClick: this.onPointerClick,
    onPointerDown: this.onPointerDown,
    onPointerUp: this.onPointerUp,
    onPointerMove: this.onPointerMove,
  };

  onImageLoaded = (key) => {
    window.logMessage('loaded & rendered:' + key);
    if (!this.state[key]) {
      this.setState({ [key]: true });
    }
  };

  onSDLoaded = () => {
    const durationInSeconds = (new Date() - window.tourLoadAt) / 1000;
    if (this.props?.tour.id && this.state.currentPano?._id) {
      trackTourLoadEnd({
        tour_id: this.props.tour.id,
        scene_id: this.state.currentPano?._id,
        duration: durationInSeconds,
      });
    }

    return this.onImageLoaded('sdLoaded');
  };

  onHDLoaded = () => this.onImageLoaded('hdLoaded');

  onClickPoweredBy = () => {
    this.toggleIntro(true);
    this.setState({ isFinalIntroSlide: true });
  };

  toggleIntro = (showIntro) => {
    if (showIntro !== this.state.showIntro) {
      this.setState({ showIntro });
      this.props.toggleLanding(showIntro);
    }
  };

  onUpdateIntroStep = (step) => {
    if (step === INTRO_STEP.FADING_IN) {
      this.setState({ introStatus: 'in' });
    } else if (step === INTRO_STEP.FADING_OUT) {
      this.setState({ introStatus: 'out' });
    } else if (step === INTRO_STEP.FADED_OUT) {
      this.toggleIntro(false);
    }
  };

  setIsFirstTime = (isFirstTime) => {
    this.setState({ isFirstTime });
  };

  renderLanding = () => {
    const { sdLoaded, showIntro, showSpinner, isFirstTime, tour } = this.state;
    const { setAutoRotate } = this.props;

    return (
      <>
        {showIntro && (
          <ResumeExperience
            spinnerVisible={showSpinner}
            onUpdate={this.onUpdateIntroStep}
            setAutoRotate={setAutoRotate}
            isFirstTime={isFirstTime}
            setIsFirstTime={this.setIsFirstTime}
          />
        )}
        {tour && (
          <LoadingImage
            loaded={sdLoaded}
            onClosing={() => this.setState({ spinner: false })}
            onClosed={() => this.setState({ showSpinner: false })}
            tour={tour}
          />
        )}
      </>
    );
  };

  renderPasscodePopup = () => {
    const { tour, showPasscode } = this.state;

    if (!showPasscode) return null;

    return (
      <PopupPasscode
        onClose={() =>
          this.setState({ showPasscode: false, enteredPasscode: true })
        }
        tourPasscode={tour.passcode}
      />
    );
  };

  getCameraParams = (value) => {
    this.setState({ cameraParams: value });
  };

  getSceneRotation = (value) => {
    this.setState({ sceneRotation: value });
  };

  handleApartmentPopup = () => {
    this.props.setApartmentPopup(!this.props.apartmentPopup);
  };

  render() {
    const {
      json,
      currentPano,
      isPanning,
      showSpinner,
      spinner,
      showIntro,
      introStatus,
      popupOpen,
      shouldRotate,
      showPasscode,
      enteredPasscode,
    } = this.state;
    const { currentHotspot, panoMode, tour, customer, viewMode, autoRotate } =
      this.props;

    return (
      <div className="World">
        {json && (
          <>
            <PanoErrorBoundary>
              {/* <GoogleAnalyticsTrackingComponent
                tourId={this.props?.tour.id}
                sceneId={currentPano?._id}
              /> */}
              <World3D
                ref={(ref) => {
                  this.worldEl = ref;
                }}
                json={json}
                tour={tour}
                showSpinner={spinner}
                showIntro={showIntro}
                introClosed={!spinner && introStatus === 'out'}
                autoRotate={
                  !showSpinner && !showIntro && !currentHotspot && autoRotate
                }
                customer={customer}
                panoMode={panoMode}
                isPanning={isPanning}
                currentPano={currentPano}
                onSDLoaded={this.onSDLoaded}
                onHDLoaded={this.onHDLoaded}
                onSwitchPano={({ groupId, id }) =>
                  this.selectSceneById(groupId, id)
                }
                onSelectHotspot={(Id) => this.selectHotspot(Id)}
                pointerEvents={this.pointerEvents}
                hotspotHistory={this.props.hotspotHistory}
                popupOpen={popupOpen}
                setHotspotHovering={this.setHotspotHovering}
                shouldLimitPan={tour.shouldLimitPan}
                history={this.props.history}
                shouldRotate={shouldRotate}
                viewMode={viewMode}
                setAutoRotate={this.props.setAutoRotate}
                showPasscode={showPasscode}
                enteredPasscode={enteredPasscode}
                getCameraParams={this.getCameraParams}
                getSceneRotation={this.getSceneRotation}
                handleApartmentPopup={this.handleApartmentPopup}
              />
            </PanoErrorBoundary>
            <DomErrorBoundary>
              {enteredPasscode && (
                <World2D
                  currentPano={currentPano}
                  autoRotate={autoRotate}
                  shouldRotate={shouldRotate}
                  showSpinner={showSpinner}
                  hotspotHistory={this.props.hotspotHistory}
                  toggleRotate={() => this.toggleRotate()}
                  selectSceneById={this.selectSceneById}
                  toggleMap={this.toggleMap}
                  toggleFeatured={this.toggleFeatured}
                  introStatus={introStatus}
                  onClickPoweredBy={this.onClickPoweredBy}
                  showIntro={showIntro}
                  history={this.props.history}
                  cameraParams={this.state.cameraParams}
                  sceneRotation={this.state.sceneRotation}
                  handleApartmentPopup={this.handleApartmentPopup}
                  onSwitchPano={({ groupId, id }) =>
                    this.selectSceneById(groupId, id)
                  }
                />
              )}
            </DomErrorBoundary>
            <RouteHandlers />
            <HidenUiByQueryParams />
            <AutoPlayModeByQueryParams />
          </>
        )}
        {this.renderPasscodePopup()}
        {this.renderLanding()}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  currentScene: store.currentScene,
  hotspotHistory: store.hotspotHistory,
  panoMode: store.panoMode,
  customer: store?.json?.customer || null,
  tour: store.tour,
  featuredHotspot: store?.json?.featuredHotspots || [],
  viewMode: store.viewMode,
  autoRotate: store.autoRotate,
  autoPlayMode: store.autoPlayMode,
  worldParams: store.worldParams,
  isPanning: store.isPanning,
  dateLight: store.dateLight,
  apartmentPopup: store.apartmentPopup,
});

const mapDispatchToProps = {
  goToScene,
  setTourData,
  setPanoData,
  toggleMenu,
  toggleLanding,
  setAudioAvailable,
  setTourAudioAvailabe,
  setTourError,
  setAutoRotate,
  updateWorldParams,
  setPanning,
  setDateLight,
  setApartmentPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(World);
