import React from 'react';
import LumaAiIcon from 'assets/icons/luma_ai_icon.jpg';

function iconMediaLumaAi(props) {
  return (
    <div className="hotspot_icon">
      <img alt="video-icon" src={LumaAiIcon} style={{ width: '60%' }}></img>
    </div>
  );
}

export default iconMediaLumaAi;
