import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import FootStep from '../footStep';
import FootCursor from '../footCursor';
import {
  calcCamRotationY,
  updateVector3OrPointByRotationXZ,
} from 'containers/world/utils';
import {
  get2DScreenPosition,
  distanceBetween2dCoordinates,
  isPositionValid,
} from 'utils/positionHelper';
import configs from 'configs';

import { THRESHOLD_2D_DISTANCE } from 'containers/world/constant';

function TransparentCube({
  sceneData,
  active,
  onClickStep,
  prevRotation,
  scenes,
  hideFootSteps,
}) {
  const groupRef = useRef();
  const { camera } = useThree();
  const [showStep, setShowStep] = useState(false);

  const isStepVisible = useMemo(
    () => active && showStep && scenes.length > 1,
    [active, showStep, scenes]
  );

  const visibleSteps = useMemo(() => {
    if (isStepVisible) {
      return (sceneData.steps || []).filter(({ targetSceneId }) =>
        scenes.some((scene) => scene._id === targetSceneId)
      );
    }
    return [];
  }, [sceneData, scenes, isStepVisible]);

  const showFootCursor = useMemo(() => visibleSteps.length > 0, [visibleSteps]);

  useEffect(() => {
    groupRef.current.visible = false;
    setShowStep(false);

    const timer = setTimeout(() => {
      groupRef.current.visible = active;
      setShowStep(active);
    }, configs.transitionSpeed * 1000 + 400);

    return () => clearTimeout(timer);
  }, [sceneData, active]);

  useEffect(() => {
    if (active) {
      groupRef.current.rotation.y = prevRotation;
    }
  }, [prevRotation, sceneData, active]);

  const calculate2DDistance = useCallback(
    (step, prevRotation, camera, intSecPoint) => {
      const { stepPosition } = step;

      const currStepPos = updateVector3OrPointByRotationXZ(
        stepPosition,
        prevRotation,
        false
      );

      // The 2D step position after scene rotation
      const currStepPos2D = get2DScreenPosition(
        new THREE.Vector3(...currStepPos),
        camera,
        window.innerWidth,
        window.innerHeight
      );

      // The distance between the foot step and the click point in 2D
      const distance = distanceBetween2dCoordinates(currStepPos2D, intSecPoint);

      return { distance, currStepPos2D };
    },
    []
  );

  const handleSelectedStep = useCallback(
    (intSecPoint) => {
      if (visibleSteps) {
        let selectedStep;
        let shortestDistance = Number.MAX_VALUE;

        for (const step of visibleSteps) {
          const { distance, currStepPos2D } = calculate2DDistance(
            step,
            prevRotation,
            camera,
            intSecPoint
          );

          if (distance < shortestDistance && currStepPos2D.visible) {
            shortestDistance = distance;
            selectedStep = step;
          }
        }

        if (selectedStep && shortestDistance < THRESHOLD_2D_DISTANCE) {
          const {
            targetSceneId,
            stepPosition,
            cameraPosition,
            targetSceneCameraPosition,
          } = selectedStep;

          if (
            isPositionValid(stepPosition) &&
            isPositionValid(cameraPosition) &&
            isPositionValid(targetSceneCameraPosition)
          ) {
            const rotationY = calcCamRotationY(
              cameraPosition,
              targetSceneCameraPosition
            );
            const sceneRotation = [0, rotationY, 0];
            groupRef.current.visible = false;
            setShowStep(false);
            onClickStep(targetSceneId, stepPosition, sceneRotation);
          }
        }
      }
    },
    [calculate2DDistance, camera, onClickStep, prevRotation, visibleSteps]
  );

  const renderFootCursor = useMemo(() => {
    return (
      showFootCursor && (
        <FootCursor
          sceneData={sceneData}
          visibleSteps={visibleSteps}
          prevRotation={prevRotation}
          handleClick={handleSelectedStep}
          calculate2DDistance={calculate2DDistance}
        />
      )
    );
  }, [
    sceneData,
    prevRotation,
    visibleSteps,
    showFootCursor,
    handleSelectedStep,
    calculate2DDistance,
  ]);

  return (
    <>
      <group ref={groupRef}>
        {!hideFootSteps &&
          visibleSteps.map((step, index) => (
            <FootStep key={index} step={step} prevRotation={prevRotation} />
          ))}
      </group>
      {renderFootCursor}
    </>
  );
}

export default TransparentCube;
