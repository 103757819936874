import { DEFAULT_TOUR_SETING } from 'consts/defaultTourSetting';
import SCENE_RATIO, { DEFAULT_SCENE_RATIO } from 'consts/sceneRatio.const';
import HOTSPOT_TYPE from '../consts/hotspotType.const';
import MEDIA_TYPE from '../consts/mediaType.const';
import SCENE_TYPE from '../consts/sceneType.const';
import { updateVideoMediaData } from './mediaHelper';

export const getPathFromJsonUrl = (url) => {
  var path = url.split('/');
  path.pop();
  return path.join('/');
};

export const getNewImgUrl = (path, imgUrl) => {
  let newUrl = imgUrl.trim();
  if (newUrl.indexOf('http://') === 0 || newUrl.indexOf('https://') === 0) {
    return newUrl;
  }
  if (newUrl[0] !== '/') {
    newUrl = '/' + newUrl;
  }
  return path + newUrl;
};

export const convertImg = (img, path) => {
  let newImg = img;
  if (newImg) {
    if (newImg.constructor === String) {
      newImg = getNewImgUrl(path, newImg);
    }
    if (newImg.constructor === Array) {
      newImg = newImg.map((img) => getNewImgUrl(path, img));
    }
  }
  return newImg;
};
/**
 * find media has id = id which one of featuredMedia
 *@params {string} mediaId Media id
 *@params {object} list media object data
 *@returns object media
 **/
export const getFeatureMedia = (id, media) => {
  return media.find((m) => m.id === id);
};

export const getMediaHotspotItem = (media) => ({
  id: media.friendlyName,
  _id: media.id,
  media,
});

export const formatJsonImgUrl = (url, jsonData) => {
  if (jsonData.panoList) {
    const path = getPathFromJsonUrl(url);
    if (jsonData.brand && jsonData.brand.logo) {
      jsonData.brand.logo = convertImg(jsonData.brand.logo, path);
    }
    jsonData.panoList.forEach((p) => {
      Object.keys(p.images).forEach((type) => {
        if (p.images[type]) {
          Object.keys(p.images[type]).forEach((res) => {
            if (p.images[type][res]) {
              if (p.images[type][res].constructor === Array) {
                p.images[type][res].forEach((img, index) => {
                  p.images[type][res][index] = convertImg(img, path);
                });
              } else {
                p.images[type][res] = convertImg(p.images[type][res], path);
              }
            }
          });
        }
      });
      if (p.hotspots) {
        p.hotspots.forEach((h) => {
          h.icons.unread = convertImg(h.icons.unread, path);
          h.icons.read = convertImg(h.icons.read, path);
          if (h.thumb) {
            h.thumb = convertImg(h.thumb, path);
          }
        });
      }
    });
  }
  return jsonData;
};

export const formatOldJson = (json) => {
  const data = JSON.parse(JSON.stringify(json));
  if (!data.panoList) {
    return data;
  }
  const newJson = {};
  newJson.media = [];
  newJson.scenes = data.panoList.map((p) => {
    const hotspots = [];
    p.hotspots.forEach((h) => {
      const hp = {
        id: h.id,
        type: h.type === 'pano' ? HOTSPOT_TYPE.SCENE : HOTSPOT_TYPE.MEDIA,
        location: [h.location.x, h.location.y, h.location.z],
        distance: h.type === 'pano' ? h.distance : null,
        sceneLinkId: h.type === 'pano' ? h.panoId : null,
        mediaLinkId: h.type !== 'pano' ? h.id : null,
      };
      hotspots.push(hp);
      if (h.type !== 'pano' && !newJson.media.find((m) => m.id === h.id)) {
        newJson.media.push({
          id: h.id,
          title: h.title,
          friendlyName: h.id,
          type:
            h.type === 'youtube-video' ? MEDIA_TYPE.YOUTUBE : MEDIA_TYPE.IMAGE,
          text: h.type === 'info-box' ? h.content.description : null,
          imageURL: h.type === 'info-box' ? h.content.images[0] : null,
          readMoreURL: h.type === 'info-box' ? h.content.moreInfoLink : null,
          youtubeID: h.type === 'youtube-video' ? h.content : null,
        });
      }
    });
    const scene = {
      id: p.id,
      title: p.title,
      friendlyName: p.id,
      type: String(p.type || SCENE_TYPE.GROUND).toUpperCase(),
      isDefault: p.default || false,
      location: '',
      viewingAngle: 70,
      defaultOrientation: [
        p.defaultOrientation.x,
        p.defaultOrientation.y,
        p.defaultOrientation.z,
      ],
      coordinates: [
        (p.coordinates && p.coordinates.lng) || 0,
        (p.coordinates && p.coordinates.lat) || 0,
        (p.coordinates && p.coordinates.alt) || 0,
      ],
      lensFlareLocation: [p.lensflare.x, p.lensflare.y, p.lensflare.z],
      noLensFlare: !(p.lensflare || p.lensflare.x) ? true : false,
      yaw: '',
      elevation: '',
      previewImgUrl: p.thumb,
      cubeMapImages: {
        size1024: p.images.cube.low,
        size2048: p.images.cube.high,
      },
      hotspots: hotspots,
    };
    return scene;
  });
  newJson.menu = data.menu.items.map((m, i) => ({
    name: m.title,
    order: i,
    scenes: m.panos.map((p, j) => ({
      id: p.id,
      order: j,
    })),
  }));
  return newJson;
};

export const updateDefaultValues = (data) => {
  if (data.customer && data.tour) {
    data.customer.brandColor1 = data.customer.brandColor1 || 'white';
    data.tour.logoURL = data.tour.logoURL || data.customer.logoURL;
    data.tour.tagline = data.tour.tagline || data.customer.companyTagline;
    data.tour.menuHighlightColor = data.tour.menuHighlightColor || 'white';
    data.tour.lightboxBackgroundColor1 =
      data.tour.lightboxBackgroundColor1 || 'black';
    data.tour.lightboxBackgroundColor2 =
      data.tour.lightboxBackgroundColor2 || 'black';
    data.tour.lightboxBackgroundColor3 =
      data.tour.lightboxBackgroundColor3 || 'black';
    data.tour.lightboxHeaderColor = data.tour.lightboxHeaderColor || 'white';
    data.tour.audioAvailable = (data.media || []).find(
      (m) => m.type === MEDIA_TYPE.AUDIO || m.type === MEDIA_TYPE.TOUR_AUDIO
    )
      ? true
      : false;
    data.tour.hotspotStyle = data.tour.hotspotStyle || 'default';
  }
};
export const updateFeatureMedia = (data) => {
  data.featuredMedia = {
    map: null,
    contact: null,
    audio: null,
  };

  data.featuredHotspots = [];

  if (data.featured) {
    if (Array.isArray(data.featured)) {
      if (data.featured.length === 0) {
        return;
      }
      data.featured.forEach((media) => {
        const featuredMedia = getFeatureMedia(media.mediaId, data.media);
        if (featuredMedia) {
          data.featuredHotspots.push(getMediaHotspotItem(featuredMedia));
          fromCustomData(featuredMedia);
          const mediaType = media.type.toLowerCase();
          if (mediaType === 'form') {
            data.featuredMedia.contact = featuredMedia;
          } else data.featuredMedia[mediaType] = featuredMedia;
        }
      });
    } else {
      const mapId = data.featured.mediaId || '';
      const map = mapId ? data.media.find((m) => m.id === mapId) || null : null;
      const contactId = (data.featured.other && data.featured.other[0]) || '';
      const contact = contactId
        ? data.media.find((m) => m.id === contactId) || null
        : null;
      data.featuredMedia.map = map;
      data.featuredMedia.contact = contact;
      if (map) {
        data.featuredHotspots.push(getMediaHotspotItem(map));
      }
      if (contact) {
        data.featuredHotspots.push(getMediaHotspotItem(contact));
      }
    }
  }
};
export const updateScenesAndHotspots = (data) => {
  data.scenes.forEach((scene) => {
    scene.sceneType = String(scene.type || SCENE_TYPE.GROUND).toUpperCase();
    scene.hotspots.forEach((h) => {
      if (h.type === HOTSPOT_TYPE.MEDIA) {
        h.media = data.media.find((m) => m.id === h.mediaLinkId) || null;
        if (h.media) {
          h._id = h.media.id;
          h.id = h.media.friendlyName;
          h.friendlyName = h.media.friendlyName;
        }
      } else if (h.type === HOTSPOT_TYPE.SCENE) {
        h.scene = data.scenes.find((s) => s.id === h.sceneLinkId) || null;
        if (h.scene) {
          h._id = h.scene.id;
          h.id = h.scene.friendlyName;
          h.friendlyName = h.scene.friendlyName;
        }
      }
    });
  });
};

export const updateMenuItemsWithScene = (data) => {
  data.menu.forEach((g) => {
    g.scenes.forEach((s) => {
      const scene = data.scenes.find((d) => d.id === s.id);
      if (scene) {
        if (scene.tourGroupId && data.groups) {
          const group = data.groups.find((g) => g.id === scene.tourGroupId);
          if (group) {
            s.groupId = group.friendlyName;
          }
        }
        s._id = s.id;
        s.id = scene.friendlyName;
        s.title = s.title || scene.title;
        s.friendlyName = scene.friendlyName;
        s.type = String(scene.type || SCENE_TYPE.GROUND).toUpperCase();
      }
    });
  });
};

export const replaceItemIdWithFriendlyName = (list = []) => {
  list.forEach((item) => {
    if (item.friendlyName) {
      item._id = item.id;
      item.id = item.friendlyName;
    }
  });
};

const replacegroupIdWithFriendlyName = (data) => {
  data.scenes.forEach((scene) => {
    let group = data.groups.find((g) => g.id === scene.tourGroupId);
    if (!group) {
      group = data.groups.find((g) => g.isDefault) || data.groups[0];
    }
    scene.groupId = group ? group.friendlyName : 'welcome';
  });
};

const fromCustomData = (media) => {
  if (media.type === MEDIA_TYPE.CUSTOM) {
    let customData = null;
    try {
      customData = JSON.parse(media.customData);
      //Parse TypeForm
      if (customData.type === MEDIA_TYPE.TYPEFORM && customData.url) {
        media.type = MEDIA_TYPE.TYPEFORM;
        media.typeForm = {
          url: customData.url,
        };
      }
      // Parse Logo Override
      else if (customData.type === MEDIA_TYPE.LOGO_OVERRIDE && customData.url) {
        media.type = MEDIA_TYPE.LOGO_OVERRIDE;
        media.logoOverride = {
          url: customData.url,
        };
      }
      // Parse Google360
      else if (
        customData.type === MEDIA_TYPE.GOOGLE360 &&
        customData.google360Url
      ) {
        media.type = MEDIA_TYPE.GOOGLE360;
        media.google360 = {
          url: customData.google360Url,
        };
      } else if (
        customData.type === MEDIA_TYPE.SKETCH_FAB &&
        customData.sketchFab
      ) {
        media.type = MEDIA_TYPE.SKETCH_FAB;
        media.sketchFab = {
          url: customData.sketchFab,
        };
      } else if (customData.type === MEDIA_TYPE.LUMA && customData.lumaUrl) {
        media.type = MEDIA_TYPE.LUMA;
        media.luma = {
          lumaUrl: customData.lumaUrl,
        };
      }
      // Parse ShapeSpark
      else if (
        customData.type === MEDIA_TYPE.SHAPESPARK &&
        customData.shapeSparkUrl
      ) {
        media.type = MEDIA_TYPE.SHAPESPARK;
        media.shapeSpark = {
          url: customData.shapeSparkUrl,
        };
      } else if (customData.type === MEDIA_TYPE.VIDEO) {
        media.type = MEDIA_TYPE.VIDEO;
        const videoData = updateVideoMediaData(customData);
        media.video = videoData;
      } else if (customData.type === MEDIA_TYPE.TOUR_AUDIO) {
        media.type = MEDIA_TYPE.TOUR_AUDIO;
        media.soundUrl = customData.url;
      } else if (customData.type === MEDIA_TYPE.MODEL_3D) {
        media.type = MEDIA_TYPE.MODEL_3D;
        media.modelUrl = customData.url;
        media.modelName = customData.modelName;
        media.size = customData.size;
        media.modelCoordinate = customData.modelCoordinate;
      }
    } catch (error) {
      window.logMessage('error parsing custom type from api response', error);
    }
  }
};

const parseMediaCustom = (listMedia) => {
  listMedia.forEach((m) => fromCustomData(m));
};

/**
 * Check and update tour.sceneRatio if need
 * @param {object} tour Tour detail data
 * @return {void}
 *
 * Support 2 value of ratio (2: Default, 4: Other)
 *
 * ```js
 * checkTourAspectRation({...tour, sceneRatio: 6}) => ({...tour, sceneRatio: 2})
 * ```
 */
const checkTourAspectRation = (tour) => {
  if (!tour.sceneRatio || !SCENE_RATIO.includes(tour.sceneRatio)) {
    tour.sceneRatio = DEFAULT_SCENE_RATIO;
  }
  tour.shouldLimitPan = tour.sceneRatio !== DEFAULT_SCENE_RATIO;
};

export const updateItemId = (data) => {
  replaceItemIdWithFriendlyName(data.scenes);
  parseMediaCustom(data.media);
  replaceItemIdWithFriendlyName(data.media);
  checkTourAspectRation(data.tour);
  if (data.groups) {
    replacegroupIdWithFriendlyName(data);
    replaceItemIdWithFriendlyName(data.groups);
  }
};

export const checkEnableBranding = (tour, subscriptionPlan) => {
  const subscription = tour.subscriptionPlan || subscriptionPlan;
  const isEnabled = subscription?.scopes?.find(
    (item) => item.key === 'ENABLE_BRANDING' && item.value
  );

  if (isEnabled) {
    return { ...tour };
  }
  return { ...tour, ...DEFAULT_TOUR_SETING };
};
