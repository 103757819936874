import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './style.scss';
import mouse_img from './mouse-sm-red.png';
import hand_img from './hand-sm-red.png';

const isMobile = window.innerWidth <= 768;
const iconImg = isMobile ? hand_img : mouse_img;
let isFirstShowGuideline = true;

const instruction = !isMobile
  ? 'click_n_drag_to_look_around'
  : 'touch_n_drag_to_look_around';

const MouseGuideline = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const searchParams = useSelector((state) => state.searchParams);
  const autoPlayMode = useSelector((state) => state.autoPlayMode);

  useEffect(() => {
    if (
      searchParams['mode'] === 'no-intro' &&
      !autoPlayMode &&
      isFirstShowGuideline
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [searchParams, autoPlayMode]);

  useEffect(() => {
    const handleClick = () => {
      if (show) setShow(false);
      document.removeEventListener('pointerdown', handleClick);
      document.removeEventListener('mousedown', handleClick);
      isFirstShowGuideline = false;
    };

    document.addEventListener('pointerdown', handleClick);
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('pointerdown', handleClick);
      document.removeEventListener('mousedown', handleClick);
    };
  }, [show]);

  if (!show) return null;

  return (
    <div className="mouse-guideline">
      <p className="mouse-guideline-text">{t(instruction)}</p>
      <img
        src={iconImg}
        className="mouse-guideline-img"
        alt="mouse-guideline"
      />
    </div>
  );
};

export default MouseGuideline;
