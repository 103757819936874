import { CONTROLS } from 'consts';
import { isMobile } from 'deviceDetect';

const isMobileDevice = isMobile();
const { ORBIT, DEVICE } = CONTROLS;

/**
 * Get request permission for device orientation control
 * only on iPhone with iOS > 13
 */
export const requestDeviceOrientationPermission = async () => {
  let isGranted = false;
  if (typeof DeviceOrientationEvent?.requestPermission === 'function') {
    try {
      const status = await DeviceOrientationEvent.requestPermission();
      if (status === 'granted') isGranted = true;
    } catch (error) {
      console.error('Fail to get permission for device: ', error);
    }
  }
  return isGranted;
};

/**
 * Get control mode on mobile device
 * @param {string} mode current control mode
 * @return {string} next controlMode
 */
const handleGetControlModeOnMobile = async (mode) => {
  let nextMode = ORBIT;
  if (
    mode === ORBIT &&
    window.DeviceOrientationEvent !== undefined &&
    typeof window.DeviceOrientationEvent.requestPermission === 'function'
  ) {
    // need user permisison to allow using device orientation control
    // iOS 13+
    const permissionStatus = await DeviceOrientationEvent.requestPermission();
    if (permissionStatus === 'granted') {
      nextMode = DEVICE;
    }
  } else {
    // Other mobile device
    // Android etc
    nextMode = mode === ORBIT ? DEVICE : ORBIT;
  }
  return nextMode;
};

/**
 * Handle check and return control mode
 * @param {string} mode Control Mode `orbit` | `device`
 * @return {string} mode
 */
export const switchControlMode = async (mode) => {
  // handle control mode on Desktop
  if (!isMobileDevice) return ORBIT;
  // Handle control mode on mobile
  const nextMode = await handleGetControlModeOnMobile(mode);
  return nextMode;
};
