import React, { useMemo } from 'react';

import { defaultIconBgColor } from 'consts';
import { mixColor } from 'utils/colorHelper';

const MenuIcon = ({ colorChecked, active }) => {
  const bgColor = useMemo(
    () => (active ? defaultIconBgColor : mixColor(colorChecked, '#000')),
    [active, colorChecked]
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        style={{
          fill: 'none',
          stroke: bgColor,
          opacity: 0.8,
        }}
      >
        <ellipse
          style={{ fill: bgColor }}
          cx="11"
          cy="11"
          rx="10.5"
          ry="10.5"
        />
      </g>
      <g transform="translate(3.377 4.88)">
        <path
          style={{ fill: '#fff' }}
          d="M8.75,17.612v2.374a.384.384,0,0,0,.693.227l1.388-1.89Z"
          transform="translate(-3.959 -7.915)"
        />
        <path
          style={{ fill: '#fff' }}
          d="M12.826.076A.4.4,0,0,0,12.4.046L.218,6.462a.411.411,0,0,0,.056.751L3.662,8.38l7.214-6.219L5.293,8.941,10.97,10.9a.412.412,0,0,0,.131.022.405.405,0,0,0,.211-.06.412.412,0,0,0,.191-.289L12.992.47a.412.412,0,0,0-.166-.394Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

export default MenuIcon;
