import * as THREE from 'three';
import TransparentShader from './materials/transparentShader';
import { DEFAULT_ROTATION_X_ANGLE } from 'containers/world/constant';

const MIN_SIDE = 215;

export const createTripodMarker = (imgUrl) => {
  return new Promise((resolve) => {
    const textureLoader = new THREE.TextureLoader();
    textureLoader.load(imgUrl, (texture) => {
      const width = texture.image.width;
      const height = texture.image.height;
      let planeWidth, planeHeight;

      if (width <= height) {
        planeWidth = MIN_SIDE;
        planeHeight = (planeWidth / width) * height;
      } else {
        planeHeight = MIN_SIDE;
        planeWidth = (planeHeight / height) * width;
      }

      const planeGeometry = new THREE.PlaneGeometry(planeWidth, planeHeight);

      const planeMaterial = new TransparentShader(texture);

      const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
      planeMesh.position.set(0, -499.5, 0);
      planeMesh.scale.set(-1, 1, 1);
      planeMesh.rotation.x = DEFAULT_ROTATION_X_ANGLE;
      planeMesh.renderOrder = 10;

      resolve(planeMesh);
    });
  });
};
