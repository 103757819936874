import React from 'react';

import './style.scss';

export default function ScaleArrow({ fontSize, color, rotate }) {
  return (
    <span
      className={`scale-arrow ${color}`}
      style={{ fontSize, rotate: `${rotate}deg` }}
    >
      &#x276E;
    </span>
  );
}
