import { log } from './global.utils';
import { loadImageForFrag } from './imageHelper';

const CANVAS_SIZE = 2048;
const IMAGE_SIZE = 1024;
const IMAGE_SIZE_4F = CANVAS_SIZE * 0.5;

const getSizeBasedOnScreenSize = () => {
  const maxDimension = Math.max(window.innerWidth, window.innerHeight);
  if (maxDimension >= 3000) return 4096;
  if (maxDimension >= 1800) return 2048;
  if (maxDimension >= 900) return 1024;
  return 512;
};

const DYNAMIC_SCREEN_SIZE = getSizeBasedOnScreenSize();

export const createCanvas = () => {
  const canvas = document.createElement('CANVAS');
  canvas.width = DYNAMIC_SCREEN_SIZE;
  canvas.height = DYNAMIC_SCREEN_SIZE;

  return canvas;
};

const loadFragmentOf4 = async (imgUri, ctx, position = 0) => {
  const img = await loadImageForFrag(imgUri);

  const positions = [
    { x: 0, y: 0 },
    { x: 0.5, y: 0 },
    { x: 0, y: 0.5 },
    { x: 0.5, y: 0.5 },
  ];

  if (img) {
    ctx.drawImage(
      img,
      positions[position].x * DYNAMIC_SCREEN_SIZE,
      positions[position].y * DYNAMIC_SCREEN_SIZE,
      (IMAGE_SIZE_4F / CANVAS_SIZE) * DYNAMIC_SCREEN_SIZE,
      (IMAGE_SIZE_4F / CANVAS_SIZE) * DYNAMIC_SCREEN_SIZE
    );
  }
};

const loadFragmentOf16 = async (imgUri, ctx, position = 0) => {
  const img = await loadImageForFrag(imgUri);

  const positions = [
    { x: 0, y: 0 },
    { x: 0.25, y: 0 },
    { x: 0.5, y: 0 },
    { x: 0.75, y: 0 },
    { x: 0, y: 0.25 },
    { x: 0.25, y: 0.25 },
    { x: 0.5, y: 0.25 },
    { x: 0.75, y: 0.25 },
    { x: 0, y: 0.5 },
    { x: 0.25, y: 0.5 },
    { x: 0.5, y: 0.5 },
    { x: 0.75, y: 0.5 },
    { x: 0, y: 0.75 },
    { x: 0.25, y: 0.75 },
    { x: 0.5, y: 0.75 },
    { x: 0.75, y: 0.75 },
  ];

  if (img) {
    ctx.drawImage(
      img,
      positions[position].x * DYNAMIC_SCREEN_SIZE,
      positions[position].y * DYNAMIC_SCREEN_SIZE,
      (IMAGE_SIZE / CANVAS_SIZE) * DYNAMIC_SCREEN_SIZE,
      (IMAGE_SIZE / CANVAS_SIZE) * DYNAMIC_SCREEN_SIZE
    );
  }
};

export const loadImageToCanvas = async (canvas, imageUrl) => {
  const img = await loadImageForFrag(imageUrl);
  if (img) {
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, DYNAMIC_SCREEN_SIZE, DYNAMIC_SCREEN_SIZE);
  }
};

export const loadCanvasImage = async (
  imageData = { size1024: '', size1024_4f: [''], size1024_16f: [''] },
  frag = 1,
  callback1 = null,
  callback2 = null,
  callback3 = null
) => {
  const currentQueueNo = window.imageQueueNo;
  const canvas = createCanvas();
  const ctx = canvas.getContext('2d');
  try {
    const img1024 = await loadImageForFrag(imageData.size1024);

    if (!img1024) return canvas;
    ctx.drawImage(img1024, 0, 0, DYNAMIC_SCREEN_SIZE, DYNAMIC_SCREEN_SIZE);

    callback1 && callback1(canvas);

    if (currentQueueNo !== window.imageQueueNo) {
      log('Loading queue changed');
      return canvas;
    }

    if (frag > 1) {
      const requests = Array.from(Array(4).keys()).map((i) =>
        loadFragmentOf4(imageData.size1024_4f[i], ctx, i)
      );
      await Promise.all(requests);

      callback2 && callback2(canvas);
    }

    if (currentQueueNo !== window.imageQueueNo) {
      log('Loading queue changed');
      return canvas;
    }

    if (frag > 4) {
      const requests = Array.from(Array(16).keys()).map((i) =>
        loadFragmentOf16(imageData.size1024_16f[i], ctx, i)
      );
      await Promise.all(requests);

      callback3 && callback3(canvas);
    }
  } catch (err) {
    log(err, canvas);
  }

  return canvas;
};
