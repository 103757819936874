import React, { useCallback, useMemo } from 'react';
import DayTimeSwitch from 'components/DayTimeSwitch';
import { isDayOrNight, getOppositeScene } from './dayNight.const';
import { useDispatch, useSelector } from 'react-redux';
import { setDateLight } from 'store/actions';

export default function CheckingSwitchDayTime({ currentPano, onSelect }) {
  const dateLight = useSelector((state) => state.dateLight);
  const dispatch = useDispatch();

  const toggleDateLight = useCallback(() => {
    const opp = getOppositeScene(currentPano?.id);
    if (opp) {
      dispatch(setDateLight(!dateLight));
      onSelect('welcome', opp, true);
    }
  }, [dateLight, onSelect, dispatch, currentPano]);

  const isVisibleDayNightControl = useMemo(
    () => isDayOrNight(currentPano?.id),
    [currentPano]
  );

  return (
    <>
      {isVisibleDayNightControl && (
        <DayTimeSwitch daylight={dateLight} setDaylight={toggleDateLight} />
      )}
    </>
  );
}
