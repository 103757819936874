export const HOTSPOT_UI = {
  DEFAULT: 'default',
  CALLOUT: 'callout',
  AIR_BALLOON: 'air-balloon',
};

export const LAYOUT_UI = {
  GALLERY: 'gallery',
  SCHOOL: 'school',
  AACORPORATION: 'aqua',
  KHAIHOANPRIME: 'prime',
};
