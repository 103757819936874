import React from 'react';

import MatterportIcon from 'assets/icons/Matterport_icon.png';

function iconMatterport(props) {
  return (
    <div className="hotspot_icon">
      <img alt="video-icon" src={MatterportIcon} style={{ width: '60%' }}></img>
    </div>
  );
}

export default iconMatterport;
