import React from 'react';

function Tip({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="125"
      height="125"
      viewBox="0 0 125 125"
    >
      <defs>
        {/* <style>.a,.c{fill:none;stroke:#000;strokeWidth:2px;}.b{clip-path:url(#a);}.c{stroke-linecap:round;stroke-linejoin:round;}</style> */}
        <clipPath id="a">
          <rect
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeWidth: '2px',
            }}
            width="125"
            height="125"
            transform="translate(0.043)"
          />
        </clipPath>
      </defs>
      <g style={{ clipPath: 'url(#a)' }} transform="translate(-0.043)">
        <g transform="translate(12.137 29.852)">
          <path
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeWidth: '2px',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            d="M8.75,17.612V28.156a1.7,1.7,0,0,0,3.078,1.009L18,20.772Z"
            transform="translate(9.41 16.148)"
          />
          <path
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeWidth: '2px',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            d="M45.207.258a1.468,1.468,0,0,0-1.493-.1L.769,21.942a1.376,1.376,0,0,0,.2,2.549l11.939,3.964L38.332,7.338,18.657,30.362,38.666,37a1.5,1.5,0,0,0,.462.074,1.47,1.47,0,0,0,.744-.2,1.4,1.4,0,0,0,.672-.983L45.793,1.6A1.376,1.376,0,0,0,45.207.258Z"
            transform="translate(0 0)"
          />
          <g transform="translate(55.358 18.453)">
            <path
              style={{
                fill: 'none',
                stroke: `${color}`,
                strokeWidth: '2px',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              }}
              d="M94.279,41.522c2.853.977,5.373,1.6,8.993,2.712C101.88,61.805,85.264,54.827,94.279,41.522Zm15.52-17.98c-.187-5.276-1.117-12.2-8.519-11.113-3.47.906-6.034,4.716-7.24,11.291a31.574,31.574,0,0,0,.5,11.731c.713,2.144.471,2.012,1.237,2.419,2.967.668,5.9,1.408,8.9,2.163C107.716,37.887,110.212,26.494,109.8,23.541Zm-22.844-.4a31.6,31.6,0,0,0,.5-11.731C86.253,4.83,83.689,1.018,80.218.113,72.816-.971,71.887,5.95,71.7,11.226c-.414,2.952,2.083,14.346,5.122,16.492,2.991-.755,5.928-1.494,8.9-2.163C86.483,25.148,86.241,25.28,86.954,23.136Zm-9.235,8.342c.053.668.128,1.3.267,2.17,2.349,14.862,17.4,7.917,8.725-4.882-1.41.483-2.738.879-4.458,1.377C81.1,30.478,79.551,30.915,77.719,31.478Z"
              transform="translate(-71.655 0)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Tip;
