import { useEffect, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useThree } from '@react-three/fiber';

import { setCenterLookat } from 'store/actions';
import { getCameraPositionFromLookAtCoordinates } from 'utils/positionHelper';
import { CONTROLS } from 'consts';

function LocationLookAt({ obj }) {
  const { camera, raycaster } = useThree();

  // redux store
  const dispatch = useDispatch();
  const { controlMode } = useSelector((state) => state);

  // Get Center Lookat of current camera
  const getCenterLookAt = useCallback(() => {
    if (!camera || !raycaster || !obj) return null;
    raycaster.setFromCamera({ x: 0, y: 0 }, camera);
    const intersection = raycaster.intersectObject(obj);

    if (intersection.length === 0) return null;
    const { x, y, z } = intersection[0].point;
    return getCameraPositionFromLookAtCoordinates(x, y, z);
  }, [camera, raycaster, obj]);

  const handleCenterLookAt = () => {
    const point = getCenterLookAt();
    dispatch(setCenterLookat(point));
  };

  // Handle get point when change camera control
  useEffect(() => {
    if (controlMode === CONTROLS.ORBIT) {
      handleCenterLookAt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlMode]);

  return null;
}

export default memo(LocationLookAt);
