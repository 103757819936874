import React from 'react';
import { useState } from 'react';
import InputSearch from '../../components/inputSearch';
import LogoCompany from './logo-png.png';
// import LogoCompany from './Logo.svg';
import './style.scss';
import searchByQuery from '../../services/searchApi';
import { useEffect } from 'react';
import SearchData from 'components/searchData';

function Search() {
  const [searchResult, setSearchResult] = useState([]);
  const [firstInit, setFirstInit] = useState(true);

  useEffect(() => {}, [searchResult]);

  const onSubmitSearch = async (query) => {
    const res = await searchByQuery(query);
    setFirstInit(false);
    if (res.isSuccess) {
      if (res.data.length > 0) {
        setSearchResult(res.data);
      } else {
        setSearchResult(null);
      }
    } else {
      console.error('ERROR WHEN SEARCHING');
    }
  };

  return (
    <>
      <div className="search-nav ">
        <div className="container">
          <div className="search-nav-logo">
            <img
              className="logo-img"
              src={LogoCompany}
              alt="Powered by Vizion"
            />
          </div>
          <div className="search-nav__field">
            <p className="search-nav-title">What are you looking for?</p>
            <InputSearch onSubmitSearch={onSubmitSearch} />
          </div>
        </div>
      </div>
      <div className="search-data">
        <div className="notification">
          {!searchResult && (
            <div className="notification__not-found">
              <p>
                The tour your looking is not found, try other keywork or access
                code
              </p>
            </div>
          )}

          {firstInit && (
            <div className="notification__info">
              <p>Use the Search Bar to find your tour</p>
            </div>
          )}
        </div>
        {searchResult &&
          searchResult.map((data) => <SearchData data={data} key={data.id} />)}
      </div>
    </>
  );
}

export default Search;
