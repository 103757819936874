import { Vector3, Vector2, PerspectiveCamera, OrthographicCamera } from 'three';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import * as ReactDOM from 'react-dom/client';
import { useFrame, useThree } from '@react-three/fiber';
import { getCanvasX, CANVAS_W, CANVAS_H } from './utils';
// import { useSelector } from 'react-redux';
// import { LAYOUT_UI } from 'consts/ui.const';

const v1 = new Vector3();
const v2 = new Vector3();
const v3 = new Vector3();

function calculatePosition1(el, camera, size = { width: 0, height: 0 }) {
  const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
  objectPos.project(camera);
  const widthHalf = size.width / 2;
  const heightHalf = size.height / 2;
  return [
    objectPos.x * widthHalf + widthHalf,
    -(objectPos.y * heightHalf) + heightHalf,
  ];
}

function calculatePosition2(el, x, y, camera, size = { width: 0, height: 0 }) {
  const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
  const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
  const deltaCamObj = objectPos.sub(cameraPos);
  const camDir = camera.getWorldDirection(v3);
  const isBehind = deltaCamObj.angleTo(camDir) > Math.PI / 2;
  const isVisible =
    !isBehind &&
    !(
      x < size.width * 0.1 ||
      x > size.width * 0.9 ||
      y < size.height * 0.1 ||
      y > size.height * 0.9
    );
  return [isVisible, isBehind];
}

function calculatePosition(el, camera, size = { width: 0, height: 0 }) {
  const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
  objectPos.project(camera);
  const widthHalf = size.width / 2;
  const heightHalf = size.height / 2;
  const x = objectPos.x * widthHalf + widthHalf;
  const y = -(objectPos.y * heightHalf) + heightHalf;
  const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
  const deltaCamObj = objectPos.sub(cameraPos);
  const camDir = camera.getWorldDirection(v3);
  const isBehind = deltaCamObj.angleTo(camDir) > Math.PI / 2;
  const isVisible =
    !isBehind &&
    !(
      x < size.width * 0.1 ||
      x > size.width * 0.9 ||
      y < size.height * 0.1 ||
      y > size.height * 0.9
    );
  return [x, y, isVisible, isBehind];
}

function objectScale(el, camera) {
  if (camera instanceof PerspectiveCamera) {
    const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
    const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
    const vFOV = (camera.fov * Math.PI) / 180;
    const dist = objectPos.distanceTo(cameraPos);
    return 1 / (2 * Math.tan(vFOV / 2) * dist);
  }
  if (camera instanceof OrthographicCamera) return camera.zoom;
  return 1;
}

const objectZIndex = (el, camera, zIndexRange) => {
  if (
    camera instanceof PerspectiveCamera ||
    camera instanceof OrthographicCamera
  ) {
    const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
    const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
    const dist = objectPos.distanceTo(cameraPos);
    const A = (zIndexRange[1] - zIndexRange[0]) / (camera.far - camera.near);
    const B = zIndexRange[1] - A * camera.far;
    return Math.round(A * dist + B);
  }
  return undefined;
};

const ori = new Vector2(CANVAS_W / 2, CANVAS_H / 2);

const getPercent = (x, y, percent) => {
  const tp = new Vector2(x - ori.x, y - ori.y);
  tp.multiplyScalar(percent);
  return { x: tp.x + ori.x, y: tp.y + ori.y };
};

const ANIMATION_DURATION = 500;

const getElapsed = (time) => Math.min(new Date() - time, ANIMATION_DURATION);

const handleDrawLine = (lineColor, ctx, x, y) => {
  if (lineColor) {
    drawDashHsLine(ctx, x, y, lineColor);
  } else {
    drawHsLine(ctx, x, y);
  }
};

const drawHsLinePercent = (ctx, toX, toY, visibleAt, onVisible, lineColor) => {
  const elapsed = getElapsed(visibleAt);
  const percent = elapsed / ANIMATION_DURATION;
  if (elapsed < ANIMATION_DURATION) {
    const { x, y } = getPercent(toX, toY, percent);
    handleDrawLine(lineColor, ctx, x, y);
  } else {
    handleDrawLine(lineColor, ctx, toX, toY);
    if (percent === 1) {
      onVisible && onVisible();
    }
  }
};

const drawHsLine = (ctx, toX, toY) => {
  ctx.beginPath();
  ctx.moveTo(ori.x, ori.y);
  ctx.lineTo(toX, toY);
  ctx.lineWidth = 1;
  ctx.strokeStyle = 'black';
  ctx.stroke();
};

const drawDashHsLine = (ctx, toX, toY, color) => {
  // tạo đổ bóng
  ctx.beginPath();
  ctx.moveTo(ori.x + 1, ori.y + 1);
  ctx.lineTo(toX + 1, toY + 1);
  ctx.lineWidth = 2;
  ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
  ctx.setLineDash([4, 4]);
  ctx.stroke();

  // vẽ stroke thật
  ctx.beginPath();
  ctx.moveTo(ori.x, ori.y);
  ctx.lineTo(toX, toY);
  ctx.lineWidth = 2;
  ctx.strokeStyle = color;
  ctx.setLineDash([4, 4]);
  ctx.stroke();
};

const onPositionChanged = (
  x,
  isVisible,
  size,
  up,
  cvRef,
  hsRef,
  visibleAt,
  onVisible,
  lineColor
) => {
  hsRef.parentNode.style.display = isVisible ? 'block' : 'none';
  const ctx = cvRef.getContext('2d');
  ctx.clearRect(0, 0, CANVAS_W, CANVAS_H);
  if (isVisible) {
    const target = {
      x: getCanvasX(x, size.width),
      y: up ? 0 : CANVAS_H,
    };
    const hs2d = {
      x: target.x - CANVAS_W / 2,
      y: (up ? -1 : 1) * 0.5 * CANVAS_H,
    };
    drawHsLinePercent(ctx, target.x, target.y, visibleAt, onVisible, lineColor);
    const elapsed = getElapsed(visibleAt);
    if (elapsed < ANIMATION_DURATION) {
      hsRef.style.opacity = '0';
    } else {
      hsRef.style.opacity = '1';
    }
    hsRef.style.transform = `translate(${hs2d.x}px,${hs2d.y}px)`;
  }
};

export default React.forwardRef(
  (
    {
      children,
      eps = 0.001,
      style,
      className,
      prepend,
      center,
      fullscreen,
      portal,
      scaleFactor,
      zIndexRange = [16777271, 0],
      hotspotRef,
      canvasRef,
      onVisible,
      configsData,
      ...props
    },
    ref
  ) => {
    const { gl, scene, camera, size } = useThree();
    const [el] = useState(() => document.createElement('div'));
    const group = useRef(null);
    const root = useRef();
    const old = useRef([0, 0]);
    const up = useMemo(
      () => props.forceUp || props.position[1] > 0,
      [props.position, props.forceUp]
    );
    const target = portal?.current ?? gl.domElement.parentNode;
    const interval = useRef(null);
    const visibleAt = useRef(null);
    const lineColor = configsData?.lineColor || 'black';

    useEffect(() => {
      if (group.current) {
        const currentRoot = (root.current = ReactDOM.createRoot(el));
        scene.updateMatrixWorld();
        const [x, y, isVisible] = calculatePosition(
          group.current,
          camera,
          size
        );
        if (canvasRef.current && hotspotRef.current) {
          if (isVisible) {
            if (!visibleAt.current) {
              visibleAt.current = new Date();
            }
          } else {
            visibleAt.current = null;
          }
          onPositionChanged(
            x,
            isVisible,
            size,
            up,
            canvasRef.current,
            hotspotRef.current,
            visibleAt.current,
            onVisible,
            lineColor
          );
        }
        el.style.cssText = `position:absolute;top:0;left:0;transform:translate3d(${x}px,${y}px,0);transform-origin:0 0;`;
        if (target) {
          if (prepend) target.prepend(el);
          else target.appendChild(el);
        }
        return () => {
          if (target) target.removeChild(el);
          currentRoot.unmount();
        };
      }
      // eslint-disable-next-line
    }, [target]);

    const styles = useMemo(
      () => ({
        position: 'absolute',
        transform: center ? 'translate3d(-50%,-50%,0)' : 'none',
        ...(fullscreen && {
          top: -size.height / 2,
          left: -size.width / 2,
          width: size.width,
          height: size.height,
        }),
        ...style,
      }),
      [style, center, fullscreen, size]
    );

    useEffect(() => {
      root.current &&
        root.current.render(
          <div
            ref={ref}
            style={styles}
            className={className}
            children={children}
          />
        );
    });

    useFrame(() => {
      if (group.current) {
        const [x, y] = calculatePosition1(group.current, camera, size);
        if (
          Math.abs(old.current[0] - x) > eps ||
          Math.abs(old.current[1] - y) > eps
        ) {
          const [isVisible, isBehind] = calculatePosition2(
            group.current,
            x,
            y,
            camera,
            size
          );
          el.style.display = isBehind ? 'none' : 'block';
          if (canvasRef.current && hotspotRef.current) {
            if (isVisible) {
              visibleAt.current = visibleAt.current || new Date();
            } else {
              visibleAt.current = null;
            }
            if (interval.current) {
              clearInterval(interval.current);
              interval.current = null;
            }
            onPositionChanged(
              x,
              isVisible,
              size,
              up,
              canvasRef.current,
              hotspotRef.current,
              visibleAt.current,
              onVisible,
              lineColor
            );
            if (interval.current === null) {
              interval.current = setInterval(() => {
                if (!canvasRef.current || !hotspotRef.current) {
                  return clearInterval(interval.current);
                }
                const elapsed = getElapsed(visibleAt.current);
                onPositionChanged(
                  x,
                  isVisible,
                  size,
                  up,
                  canvasRef.current,
                  hotspotRef.current,
                  visibleAt.current,
                  onVisible,
                  lineColor
                );
                if (elapsed > ANIMATION_DURATION) {
                  clearInterval(interval.current);
                }
              }, 10);
            }
          }
          const scale =
            scaleFactor === undefined
              ? 1
              : objectScale(group.current, camera) * scaleFactor;
          el.style.transform = `translate3d(${x}px,${y}px,0) scale(${scale})`;
          el.style.zIndex = `${objectZIndex(
            group.current,
            camera,
            zIndexRange
          )}`;
        }
        old.current = [x, y];
      }
    });

    return <group {...props} ref={group} />;
  }
);
