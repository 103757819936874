import React from 'react';

function Tip({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="124"
      height="125"
      viewBox="0 0 124 125"
    >
      <defs>
        <clipPath id="a">
          <rect
            style={{ fill: 'none', stroke: `${color}` }}
            width="124"
            height="125"
            transform="translate(-0.276)"
          />
        </clipPath>
      </defs>
      <g style={{ clipPath: 'url(#a)' }} transform="translate(0.276)">
        <g transform="translate(10.901 30.222)">
          <path
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeLinecap: 'round',
              strokeWidth: '2px',
              strokeMiterlimit: 10,
            }}
            d="M731.64-1870.812c8.945,3.456,16.665,9.036,16.665,14.236,0,8.764-15.648,16.156-37.025,18.452"
            transform="translate(-646.1 1896.938)"
          />
          <path
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeLinecap: 'round',
              strokeWidth: '2px',
              strokeMiterlimit: 10,
            }}
            d="M294.471-1849.22c-24.307-1.479-42.866-9.4-42.866-18.941,0-6.375,10.409-12.661,23.15-16.151"
            transform="translate(-251.605 1908.523)"
          />
          <rect
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeLinecap: 'round',
              strokeWidth: '2px',
              strokeMiterlimit: 10,
            }}
            width="62.39"
            height="46.225"
            transform="translate(23.15 0)"
          />
          <path
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeLinecap: 'round',
              strokeWidth: '2px',
              strokeLinejoin: 'round',
            }}
            d="M503.868-1907.038l10.776-16.023,5.955,8.011"
            transform="translate(-468.098 1941.778)"
          />
          <path
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeLinecap: 'round',
              strokeWidth: '2px',
              strokeLinejoin: 'round',
            }}
            d="M597.868-1954.24l13.612-23.822L625.8-1954.24"
            transform="translate(-548.769 1988.979)"
          />
          <ellipse
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeLinecap: 'round',
              strokeWidth: '2px',
              strokeLinejoin: 'round',
            }}
            cx="1.856"
            cy="1.856"
            rx="1.856"
            ry="1.856"
            transform="translate(44.548 9.062)"
          />
          <path
            style={{
              fill: 'none',
              stroke: `${color}`,
              strokeLinecap: 'round',
              strokeWidth: '2px',
              strokeLinejoin: 'round',
            }}
            d="M537.867-1674.062l3.545,5.279-3.545,4.5"
            transform="translate(-497.277 1728.086)"
          />
        </g>
      </g>
    </svg>
  );
}

export default Tip;
