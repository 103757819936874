import { useSelector } from 'react-redux';

import LineList from '../../hotspots/line';

const LineListHandler = ({ showHP, ...restProps }) => {
  const { isLandingOpen } = useSelector((state) => state);
  const { hotspotId: popupOpen } = useSelector((state) => state.worldParams);

  if (!isLandingOpen && !popupOpen && restProps.hotspots.length) {
    return <LineList {...restProps} />;
  }

  return null;
};

export default LineListHandler;
