import { all, takeLatest } from 'redux-saga/effects';
import * as APP_ACTION from './actionTypes';
import middlewares from './middlewares';

function* handleInit() {
  yield takeLatest(APP_ACTION.INITIATE, middlewares.initiate);
}

export default function* rootSaga() {
  yield all([handleInit()]);
}
