import React, { useRef, memo } from 'react';
import LocationLookAt from './locationLookAt';

function DetectControlSwitch() {
  const meshRef = useRef(null);

  return (
    <mesh ref={meshRef} position={[0, 0, 0]} scale={[-1000, 1000, 1000]}>
      <boxGeometry args={[1, 1, 1, 1, 1, 1]} />
      <meshBasicMaterial transparent opacity={0} />
      <LocationLookAt obj={meshRef.current} />
    </mesh>
  );
}

export default memo(DetectControlSwitch);
