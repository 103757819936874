export const STEP_WIDTH = 100;
export const CURSOR_WIDTH = 100;
export const BOX_WIDTH = 1000;
export const THRESHOLD_2D_DISTANCE = 100;

// This is the factor to determine the coordinates of the footsteps inside the transparent box
export const TRANSFORM_RATIO =
  (BOX_WIDTH - STEP_WIDTH * Math.sqrt(2)) / BOX_WIDTH;

export const INITIAL_POSITION_CURSOR = [
  0,
  -(BOX_WIDTH - STEP_WIDTH * Math.sqrt(2)),
  0,
];
export const INITIAL_ROTATION_CURSOR = [-Math.PI / 2, 0, 0];
export const TOP_Y = 0;
export const AXIS_LIMIT = BOX_WIDTH / 2;
export const DEFAULT_ROTATION_X_ANGLE = -Math.PI / 2;
export const SCALE = 1.2;
