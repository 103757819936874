import React, { useMemo } from 'react';
import { ensureNonWhite } from 'utils/colorHelper';

export const ShareIcon = ({ color: settingColor }) => {
  const color = useMemo(() => ensureNonWhite(settingColor), [settingColor]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_14241"
            data-name="Rectangle 14241"
            width="16"
            height="16"
          />
        </clipPath>
      </defs>
      <g id="icon-share" transform="translate(0 0)">
        <g id="Group_3975" data-name="Group 3975" transform="translate(0 0)">
          <path
            fill={color}
            id="Path_1549"
            data-name="Path 1549"
            d="M191.486,16.5a2.449,2.449,0,1,1,2.448-2.448,2.451,2.451,0,0,1-2.448,2.448m0-3.9a1.449,1.449,0,1,0,1.448,1.449,1.451,1.451,0,0,0-1.448-1.449"
            transform="translate(-188.786 -6.051)"
          />
          <path
            fill={color}
            id="Path_1550"
            data-name="Path 1550"
            d="M212.691,5.744A2.448,2.448,0,1,1,215.139,3.3a2.451,2.451,0,0,1-2.449,2.448m0-3.9A1.449,1.449,0,1,0,214.139,3.3a1.45,1.45,0,0,0-1.449-1.449"
            transform="translate(-199.389 -0.674)"
          />
          <rect
            fill={color}
            id="Rectangle_14239"
            data-name="Rectangle 14239"
            width="7.855"
            height="1"
            transform="translate(4.041 6.816) rotate(-29.745)"
          />
          <path
            fill={color}
            id="Path_1551"
            data-name="Path 1551"
            d="M212.691,27.256a2.449,2.449,0,1,1,2.449-2.449,2.451,2.451,0,0,1-2.449,2.449m0-3.9a1.449,1.449,0,1,0,1.449,1.448,1.45,1.45,0,0,0-1.449-1.448"
            transform="translate(-199.389 -11.43)"
          />
          <rect
            fill={color}
            id="Rectangle_14240"
            data-name="Rectangle 14240"
            width="1"
            height="7.855"
            transform="matrix(0.496, -0.868, 0.868, 0.496, 4.041, 9.184)"
          />
        </g>
      </g>
    </svg>
  );
};
