import React from 'react';
import { useThree } from '@react-three/fiber';
import Lensflare from './lensflare';
import { needLensflare } from './lensflareCheck';

function WebLensflare(props) {
  const threeContext = useThree();
  return needLensflare(props.pano) ? (
    <Lensflare position={[...props.pano.lensFlareLocation]} {...threeContext} />
  ) : null;
}

export default WebLensflare;
