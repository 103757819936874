import React, { useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BackSide } from 'three';
import { BOX_WIDTH } from './constant';

const ASPECT = 0.999;

function WorldInteract({ onClick, onPointerDown, onPointerMove, onPointerUp }) {
  const meshRef = useRef();
  const prevRotation = useSelector((state) => state.prevRotation);

  // This is to keep the box inside the scene and not affect position of lensflare.
  const boxArgs = useMemo(
    () => [BOX_WIDTH * ASPECT, BOX_WIDTH * ASPECT, BOX_WIDTH * ASPECT, 1, 1, 1],
    []
  );

  useEffect(() => {
    if (meshRef) {
      meshRef.current.rotation.y = prevRotation;
    }
  }, [prevRotation]);

  return (
    <mesh
      ref={meshRef}
      position={[0, 0, 0]}
      onClick={(e) => onClick(e)}
      onPointerDown={(e) => onPointerDown(e)}
      onPointerMove={(e) => onPointerMove(e)}
      onPointerUp={(e) => onPointerUp(e)}
    >
      <boxGeometry args={boxArgs} />
      <meshBasicMaterial
        color={'white'}
        side={BackSide}
        transparent={true}
        opacity={0}
      />
    </mesh>
  );
}

export default WorldInteract;
