import React from 'react';
import aerialIcon from './callout_aerial.svg';
import './mapLoading.scss';

const MapLoading = () => {
  return (
    <div className="map-loading">
      <img className="map-loading-icon" src={aerialIcon} alt="Map Loading" />
    </div>
  );
};

export default MapLoading;
