import React from 'react';

import FlickedArrow from './flicked-arrow.png';

const IconFlickedArrow = () => {
  return (
    <div className="icon-container">
      <img
        className="flicked-arrow-icon"
        src={FlickedArrow}
        alt="FlickedArrow-icon"
      ></img>
    </div>
  );
};

export default IconFlickedArrow;
