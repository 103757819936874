import React from 'react';
import './hotspotContainer.scss';

const HotspotContainer = React.forwardRef((props, ref) => {
  return (
    <div className="hotspot-container">
      <div ref={ref} className="hotspot-transform">
        {props.children}
      </div>
    </div>
  );
});

export default React.memo(HotspotContainer);
