import React from 'react';

function iconTypeForm(props) {
  return (
    <div className="hotspot_icon">
      <svg
        id="Group_4007"
        data-name="Group 4007"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="20"
        viewBox="0 0 512 512"
      >
        <path
          d="M408 64H104a56.16 56.16 0 00-56 56v192a56.16 56.16 0 0056 56h40v80l93.72-78.14a8 8 0 015.13-1.86H408a56.16 56.16 0 0056-56V120a56.16 56.16 0 00-56-56z"
          strokeLinejoin="round"
          strokeWidth="32px"
          fill="white"
        ></path>
        <circle cx="160" cy="216" r="32"></circle>
        <circle cx="256" cy="216" r="32"></circle>
        <circle cx="352" cy="216" r="32"></circle>
      </svg>
    </div>
  );
}

export default iconTypeForm;
