import React, { useMemo } from 'react';

import './PreviewSceneImage.scss';

export default function PreviewSceneImage({ subTopviewScenes, indexHover }) {
  const isMobile = useMemo(() => window.innerWidth < 1023, []);
  return (
    <div>
      {!isMobile &&
        subTopviewScenes &&
        subTopviewScenes.map((scene, index) => (
          <div
            className="preview-scene-image"
            style={{ opacity: indexHover === index ? 1 : 0 }}
            key={index}
          >
            <img src={scene.previewImgUrl} alt={scene.title} />
            <div className="preview-scene-title">
              <p> {scene.title}</p>
            </div>
          </div>
        ))}
    </div>
  );
}
