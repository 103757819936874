import React from 'react';
import {
  AudioIcon,
  ContactFormIcon,
  ExpandIcon,
  FloorMapIcon,
  HelpIcon,
  MapIcon,
  // ShareIcon,
} from 'components/icons';
import configs from 'configs';
// import { isMobile } from 'react-device-detect';
import { limitLetters } from 'utils/limitLetters';

// import NotePlate from './NotePlate';

import './SchoolLayoutIcons.scss';
import {
  AquaAutoRotateIcon,
  AquaFullscreenIcon,
  AquaMapfloorplanIconV1,
  ShareIcon,
} from 'components/icons/AquaIcons';
import AquaExtraIcons from './AquaExtraIcons';
import { isMobile } from 'react-device-detect';
import HoveringPlate from 'components/HoveringPlate';

export default function AquaLayoutIcons(props) {
  const {
    currentPano,
    color,
    toggleHelpButton,
    featuredMedia,
    isShowAudioIcon,
    canFullscreen,
    menuPosition,
    handleFullScreen,
    isShowMapFloorPlan,
    setIsActiveMap,
    isActiveMap,
    mapShowed,
    setOpenDropDown,
    openDropDown,
    handleSelectMap,
    scenes,
    floorMaps,
    switchMode,
    handleUpdateRoute,
    onSelect,
    isAquaStyle,
    isShowOnMap,
    shouldShowMapIcon,
  } = props;

  const handleShare = () => {
    const shareUrl =
      'https://www.facebook.com/sharer/sharer.php?u=' +
      encodeURIComponent(window.location.href);

    window.open(shareUrl, '_blank');
  };

  return (
    <div
      className={`icons_right ${isAquaStyle ? 'icons_right-container' : ''}`}
    >
      {isShowMapFloorPlan && (
        <>
          <HoveringPlate textHover={'Mặt bằng tổng thể'}>
            <div
              className={`icon position-relative ${
                isAquaStyle ? 'icon-aquaStyle' : ''
              }`}
              onClick={() => setIsActiveMap(!isActiveMap)}
            >
              {/* <MapFloorPlanIcon active={isActiveMap} /> */}
              <AquaMapfloorplanIconV1 />
            </div>
          </HoveringPlate>

          <div
            className={`mapfloorplan_container  ${
              isAquaStyle ? 'mapfloorplan_container-aacorp' : ''
            }
            ${!isActiveMap ? 'open-aacorp' : ''}
          `}
          >
            {mapShowed && (
              <>
                {floorMaps?.length > 1 && !isAquaStyle && (
                  <div className="map_header">
                    <div
                      className="map_dropdown"
                      onClick={() => setOpenDropDown(!openDropDown)}
                    >
                      <span>{limitLetters(mapShowed.image.name, 15)}</span>
                      <i className="map_arrow down"></i>
                      {openDropDown && (
                        <div
                          className={`map_dropdown_options ${
                            openDropDown ? 'show' : ''
                          }`}
                        >
                          <ul className="dropdown_items">
                            {floorMaps?.map((item, index) => (
                              <li
                                onClick={() => handleSelectMap(item)}
                                key={index}
                              >
                                {limitLetters(item.image.name, 10)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div
                      className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
                    >
                      <ExpandIcon />
                    </div>
                  </div>
                )}
                <div className="map_image">
                  <img src={mapShowed.image.url} alt={mapShowed.image.name} />
                  {mapShowed.objects.map((viewpoint) => {
                    const currentPoint = viewpoint.id === currentPano._id;
                    const [left, top] = viewpoint.coordinates;
                    const leftInPercent = left * 100;
                    const topInPercent = top * 100;
                    const currentScene =
                      scenes.find((s) => s._id === viewpoint.id) || {};

                    return (
                      <div
                        key={viewpoint.id}
                        className={`viewpoint-container  ${
                          currentPoint
                            ? 'current-viewpoint aquaAnimation'
                            : `viewpoint ${isAquaStyle ? 'aquaViewPoint' : ''}`
                        } `}
                        style={{
                          left: `${leftInPercent}%`,
                          top: `${topInPercent}%`,
                        }}
                        onClick={() =>
                          onSelect(currentScene.groupId, currentScene.id)
                        }
                      >
                        <HoveringPlate textHover={currentScene.title}>
                          {/* <p className="scene-title">{currentScene.title}</p> */}

                          <div className="hover-point"></div>
                        </HoveringPlate>
                      </div>
                    );
                  })}
                </div>

                {isAquaStyle && (
                  <div
                    className="mapfloorplan_close"
                    onClick={() => setIsActiveMap(true)}
                  >
                    <button className="mapfloorplan_close-btn">&times;</button>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}

      {isAquaStyle && <AquaExtraIcons />}

      {shouldShowMapIcon && isAquaStyle && (
        <div
          className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
          style={{
            display: isShowOnMap ? 'flex' : 'none',
          }}
          onClick={() => handleUpdateRoute(configs.mapRoute)}
        >
          <MapIcon />
        </div>
      )}

      {!isMobile && (
        <HoveringPlate textHover={'Bật tham quan tự động'}>
          <div
            className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
            onClick={() => switchMode()}
          >
            <AquaAutoRotateIcon />
          </div>
        </HoveringPlate>
      )}

      {canFullscreen() && menuPosition !== 'bottom' && (
        <HoveringPlate textHover={'Xem toàn màn hình'}>
          <div
            className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
            onClick={handleFullScreen}
          >
            <AquaFullscreenIcon />
          </div>
        </HoveringPlate>
      )}

      <HoveringPlate textHover={'Hướng dẫn'}>
        <div
          className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
          onClick={toggleHelpButton}
        >
          <HelpIcon />
        </div>
      </HoveringPlate>

      {featuredMedia.map && (
        <div
          className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
          onClick={() => handleUpdateRoute(featuredMedia.map.id)}
        >
          <FloorMapIcon />
        </div>
      )}

      {featuredMedia.contact && (
        <div
          className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
          onClick={() => handleUpdateRoute(featuredMedia.contact.id)}
        >
          <ContactFormIcon />
        </div>
      )}
      {isShowAudioIcon && (
        <AudioIcon
          activeColor={color}
          className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
        />
      )}

      <HoveringPlate textHover={'Chia sẻ'}>
        <div
          className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
          onClick={handleShare}
        >
          <ShareIcon />
        </div>
      </HoveringPlate>

      {/* <div
        className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
        onClick={() => {
          toggleRotate();
        }}
      >
        <AutoRotateIcon shouldRotate={isAutoPlay} activeColor={color} />
      </div> */}
    </div>
  );
}
