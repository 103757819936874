export const checkIsShowHotspotMap = (hotspot) => {
  if (!hotspot?.configs) return true;

  try {
    const map2Dconfigs = JSON.parse(hotspot.configs);
    if (!map2Dconfigs) return true;
    if (!map2Dconfigs.hasOwnProperty('isShowHotspot')) return true;
    return map2Dconfigs?.isShowHotspot;
  } catch (error) {
    return true;
  }
};
