import { DEFAULT_TOUR_SETING } from 'consts/defaultTourSetting';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function setVar(brandColor) {
  const doc = document.documentElement;
  doc.style.setProperty('--tour-brandColor', 'rgb(255,192,0)');
  doc.style.setProperty('--tour-brandSecondColor', '#216d8d');
}

function BrandColorHandler() {
  const brandColor = useSelector(
    (state) =>
      state.tour?.menuHighlightColor || DEFAULT_TOUR_SETING.menuHighlightColor
  );
  useEffect(() => {
    setVar(brandColor);
  }, [brandColor]);

  return null;
}

export default BrandColorHandler;
