import Color from 'color';

export const ensureNonWhite = (color) => {
  const clr = Color(color);
  const r = ((clr.red() + 1) / 256) * 100;
  const g = ((clr.green() + 1) / 256) * 100;
  const b = ((clr.blue() + 1) / 256) * 100;
  const whiteMatch = (r + g + b) / 3;
  if (whiteMatch > 90) {
    return 'black';
  }

  return color;
};

export const mixColor = (color, mixClr) => {
  const mClr = Color(color).mix(Color(mixClr));

  return `rgba(${Math.floor(mClr.color[0])},${Math.floor(
    mClr.color[1]
  )},${Math.floor(mClr.color[2])},1)`;
};
