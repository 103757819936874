import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setAutoPlay } from 'store/actions';

const AutoPlayModeByQueryParams = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const { autoPlayMode } = useSelector((state) => state);

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const existingQueries = useMemo(() => {
    const data = {};
    query.forEach((value, key) => {
      data[key] = value;
    });
    return data;
  }, [query]);

  const modeQuery = useMemo(() => existingQueries.autoPlay, [existingQueries]);

  useEffect(() => {
    if (modeQuery !== undefined && autoPlayMode !== modeQuery) {
      dispatch(setAutoPlay(JSON.parse(modeQuery)));
    }
    // eslint-disable-next-line
  }, [modeQuery]);

  useEffect(() => {
    if (modeQuery !== undefined && autoPlayMode !== modeQuery) {
      existingQueries.autoPlay = autoPlayMode;
      const newQueryStr = Object.keys(existingQueries).reduce((a, k, i, arr) => {
        return a + `${k}=${existingQueries[k]}` + ((arr.length === i + 1) ? '' : '&')
      }, '');
      history.push('?' + newQueryStr);
    }
    // eslint-disable-next-line
  }, [autoPlayMode]);
  return <></>;
};
export default AutoPlayModeByQueryParams;
