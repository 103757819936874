import React, { useCallback, useMemo, useState } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { isEqual } from 'lodash';

import { menuHighlightDefaultColor } from 'consts';
import configs from 'configs';
import { EVENT_TYPE, trackEventByType } from 'gaTracking';
import { ensureSubscriptionData, switchControlMode } from 'utils';

import { ShareIcon } from 'components/icons';
import { useNavigatedRoutes } from 'common/hooks';

import {
  setMenuPosition,
  toggleAudio,
  toggleMenu,
  togglePanoMode,
  setControlMode,
} from 'store/actions';

import MenuForMobile from 'components/MenuForMobile';
import MenuForDesktop from 'components/MenuForDesktop';
import { LAYOUT_UI } from 'consts/ui.const';
import ApartmentPopup from './ApartmentPopup';

const DEFAUlt_MAP_COORDINATES = [0, 0, 10];

function moveItemToTop(arr, targetId) {
  arr.sort((a, b) => b.objects.length - a.objects.length);

  const index = arr.findIndex((item) => {
    return item.objects.some((obj) => obj.id === targetId);
  });

  if (index !== -1) {
    const itemToMove = arr.splice(index, 1)[0];
    arr.unshift(itemToMove);
  }

  return arr;
}

const MenuV2 = (props) => {
  const isPhone = useMediaQuery({ query: '(max-width: 480px)' });
  const {
    logoURL,
    hasLogo,
    isSchoolLayout,
    isAquaStyle,
    isPrimeStyle,
    apartmentPopup,
  } = useSelector((state) => ({
    logoURL: state.logoURL,
    hasLogo: state.hasLogo,
    apartmentPopup: state.apartmentPopup,
    isSchoolLayout: state.tour.menuStyle === LAYOUT_UI.SCHOOL,
    isAquaStyle: state.tour.menuStyle === LAYOUT_UI.AACORPORATION,
    isPrimeStyle: state.tour.menuStyle === LAYOUT_UI.KHAIHOANPRIME,
  }));

  const [openMenu, setOpenMenu] = useState(
    !(props.tour.menuStyle === LAYOUT_UI.SCHOOL)
  );

  const enableBranding = useSelector(
    ({ subscriptionPlan }) => subscriptionPlan.enableBranding
  );
  const menuHighlightColor = props.json.tour.menuHighlightColor;

  const dispatch = useDispatch();
  const [handleUpdateRoute] = useNavigatedRoutes();

  const menuOpenScene = (gId, sId) => {
    props.onSelect(gId, sId);
    trackEventByType(EVENT_TYPE.SCENE_FROM_MENU, sId);
  };
  const { controlMode } = useSelector((state) => state);

  const handleSwitchControlMode = useCallback(async () => {
    const switchToMode = await switchControlMode(controlMode);
    if (switchToMode !== controlMode) dispatch(setControlMode(switchToMode));
  }, [controlMode, dispatch]);

  const isShowOnMap = useMemo(
    () => !isEqual(props.tourMapCoordinates, DEFAUlt_MAP_COORDINATES),
    [props.tourMapCoordinates]
  );

  const hightLightColor = useMemo(
    () => (enableBranding ? menuHighlightColor : menuHighlightDefaultColor),
    [enableBranding, menuHighlightColor]
  );

  const isShowAudioIcon = useMemo(() => props.audio.available, [props.audio]);

  const floorMaps = useMemo(() => {
    const mapFloorPlansSet = new Set();
    props?.currentPano?.hotspots.forEach((item) => {
      const floorPlans =
        item?.media?.type === 'MapFloorPlan'
          ? item.media.mapFloorPlan
          : item?.type === 'MapFloorPlan'
          ? item.mapFloorPlan
          : null;
      if (floorPlans) {
        floorPlans.forEach((floorPlan) => {
          mapFloorPlansSet.add(floorPlan);
        });
      }
    });

    return moveItemToTop(Array.from(mapFloorPlansSet), props.currentPano?._id);
  }, [props.currentPano]);

  const isShowMapFloorPlan = useMemo(
    () => !!floorMaps.length || props?.currentPano?.isShowMapFloorPlan,
    [floorMaps.length, props?.currentPano?.isShowMapFloorPlan]
  );

  return (
    <>
      <div
        className={`tour-infor ${
          props.isNavOpen ? 'tour-infor_navMenuOpen' : ''
        } ${isSchoolLayout ? 'tour-infor_school-layout' : ''}`}
      >
        {enableBranding && hasLogo && !isAquaStyle && !isPrimeStyle && (
          <div className={`tour-logo `}>
            <img alt="tour-logo" src={logoURL}></img>
          </div>
        )}

        {!isSchoolLayout && !isAquaStyle && !isPrimeStyle && (
          <div className="tour-icons">
            <div
              className="icon-share"
              onClick={() => handleUpdateRoute(configs.socialRoute)}
            >
              <ShareIcon color={hightLightColor} />
            </div>
          </div>
        )}
      </div>

      {isPhone ? (
        <MenuForMobile
          menuOpenScene={menuOpenScene}
          handleSwitchControlMode={handleSwitchControlMode}
          color={hightLightColor}
          isShowOnMap={isShowOnMap}
          isShowAudioIcon={isShowAudioIcon}
          floorMaps={floorMaps}
          isShowMapFloorPlan={isShowMapFloorPlan}
          isAquaStyle={isAquaStyle}
          isPrimeStyle={isPrimeStyle}
          {...props}
        />
      ) : (
        <MenuForDesktop
          menuOpenScene={menuOpenScene}
          handleSwitchControlMode={handleSwitchControlMode}
          color={hightLightColor}
          isShowOnMap={isShowOnMap}
          isShowAudioIcon={isShowAudioIcon}
          floorMaps={floorMaps}
          isShowMapFloorPlan={isShowMapFloorPlan}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          isAquaStyle={isAquaStyle}
          isPrimeStyle={isPrimeStyle}
          {...props}
        />
      )}

      {apartmentPopup && (
        <ApartmentPopup
          handleApartmentPopup={props.handleApartmentPopup}
          onSelect={props.onSelect}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  dimentions,
  menu,
  audio,
  json,
  currentScene,
  tour,
}) => ({
  dimentions,
  isNavOpen: menu.isOpen,
  menuPosition: menu.position,
  mapPopupOpen: menu.isoMetricMap,
  socialPopupOpen: menu.social,
  audio,
  json,
  featured: json.featured,
  featuredMedia: json.featuredMedia,
  tourMapCoordinates: json.tour.mapCoordinates,
  showOnMap: currentScene.showOnMap,
  scope: ensureSubscriptionData(tour.subscriptionPlan.scopes),
  tour,
});

const mapDispatchToProps = {
  toggleMenu,
  togglePanoMode,
  setMenuPosition,
  toggleAudio,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuV2);
