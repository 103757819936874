import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import { initGtag } from 'gaTracking';
import i18n from './utils/i18next';
import store from './store';
import App from './App';
import './utils/global.utils';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import './index.css';

function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
}

window.addEventListener('resize', appHeight);
appHeight();
initGtag();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Suspense>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
serviceWorker.unregister();
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
