import React from 'react';
import { connect } from 'react-redux';
import Audio from './audio';
import TourAudio from './tourAudio';

window.globalAudioPlayed = false;

const AmbientAudio = (props) => {
  const { spinnerOn, scene } = props;

  return (
    <>
      {!spinnerOn && (
        <>
          <Audio scene={scene} />
          <TourAudio />
        </>
      )}
      <audio
        id="globalAudio"
        controls={false}
        onPlay={() => {
          window.globalAudioPlayed = true;
        }}
        autoPlay={false}
        src="/assets/audio/quiet_noise.mp3"
      />
    </>
  );
};

const mapStateToProps = ({ audio }) => ({ audio });

export default connect(mapStateToProps)(AmbientAudio);
