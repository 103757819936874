import REGEX from 'consts/regex.const';
const platforms = ['vimeo', 'youtube', 'youtu.be'];

const getVideoId = {
  vimeo: (url) => {
    const match = url.match(REGEX.VIMEO_ID);
    return (match && match[1]) || '';
  },
  youtube: (url) => {
    const match = url.match(REGEX.YOUTUBE_ID);
    return match && match[7].length === 11 ? match[7] : '';
  },
};

/**
 * Get video id by platform
 * @param {string} url Video Url
 * @param {string} platform video platform
 * @return {string} videoId
 */
export const getVideoIdByPlatform = (url, platform) => {
  if (!url || !platform) return '';

  return getVideoId[platform] ? getVideoId[platform](url) : '';
};

/**
 * Check video platform from url
 * @param {string} url video url
 * @returns {string}
 *
 * ```js
 * checkVideoType('https://www.youtube.com/watch?v=UgfsbL-uHOA') => 'youtube'
 * ```
 */
const checkVideoType = (url) => {
  const platform = platforms.find((p) => url.includes(p));

  if (platform) {
    return platform === 'youtu.be' ? 'youtube' : platform;
  }

  return '';
};

/**
 * Update video media data for video type
 * @param {object} videoData video media data
 * @return {object} parsed custom video data
 *
 * Support CUSTOM.VIDEO, CUSTOM.VIMEO, MEDIA.YOUTUBE
 *
 * ```js
 * videoData = {}
 * updateVideoMediaData(videoData) => ({...videoData, platform: 'Vimeo', videoId: 'abcXYZ'})
 * ```
 */
export const updateVideoMediaData = (videoData) => {
  const { videoUrl } = videoData;

  const platform = checkVideoType(videoUrl);
  if (!platform) return videoData;

  const videoId = getVideoIdByPlatform(videoUrl, platform);

  return {
    ...videoData,
    videoId,
    platform,
  };
};

export const isMatterportUrl = (mpUrl) => {
  if (!mpUrl.includes('matterport.com')) return '';

  if (mpUrl.includes('/show')) {
    const urlParses = mpUrl.split('?');
    const urlParams = urlParses[1].split('&');
    return (
      (urlParams &&
        urlParams.find((p) => p.indexOf('m=') === 0).split('=')[1]) ||
      ''
    );
  } else if (mpUrl.includes('/discover/space/')) {
    return mpUrl.split('/space/')[1];
  }

  return '';
};
