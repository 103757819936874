import React, { useState, useEffect } from 'react';

import { connect, useSelector } from 'react-redux';

import { isMenuLeft } from 'utils/menuHelper';
import { isPhone } from 'deviceDetect';

import IntroSlider from './introSlider';

import './intro.scss';

const PHONE = isPhone();

export const INTRO_STEP = {
  RENDER: 0,
  FADING_IN: 1,
  FADED_IN: 2,
  FADING_OUT: 3,
  FADED_OUT: 4,
};

const Intro = (props) => {
  const { spinnerVisible, dimentions, onUpdate } = props;
  const portrait = window.innerWidth < window.innerHeight;
  const [step, setStep] = useState(INTRO_STEP.RENDER);
  const [status, setStatus] = useState('out');
  const [enabled, setEnabled] = useState(true);

  const highlightColor = useSelector((state) => state.tour.menuHighlightColor);

  useEffect(() => {
    if (step === INTRO_STEP.FADING_IN) {
      setStatus('in');
      setTimeout(() => setStep(INTRO_STEP.FADED_IN), 1000);
    }
    if (step === INTRO_STEP.FADING_OUT) {
      setStatus('out');
      setTimeout(() => setStep(INTRO_STEP.FADED_OUT), 1000);
    }
    onUpdate(step);
    // eslint-disable-next-line
  }, [step]);
  useEffect(() => {
    if (!spinnerVisible && step === INTRO_STEP.RENDER) {
      setStep(INTRO_STEP.FADING_IN);
    }
  }, [step, spinnerVisible]);

  const onHide = () => {
    setEnabled(false);
    setStep(INTRO_STEP.FADING_OUT);
  };

  const onClickHide = () => {
    if (enabled) {
      if (!window.globalAudioPlayed) {
        document.getElementById('globalAudio').play();
        if (window.tourAudioPlayed) {
          document.getElementById('tourAudio').play();
        }
        if (window.haveAudioSrc) {
          const audio = document.getElementsByClassName('sceneAudio');
          for (const key in audio) {
            if (Object.hasOwnProperty.call(audio, key)) {
              const element = audio[key];
              element.play();
            }
          }
        }
      }
      onHide();
    }
  };

  return (
    <>
      <div
        className={`landing-intro ${status} menu-${
          isMenuLeft(dimentions) ? 'left' : 'bottom'
        } ${PHONE ? 'phone' : 'desktop'} ${
          portrait ? 'portrait' : 'landscape'
        }`}
      >
        <div className="intro-title">
          <p>Welcome</p>
          <button
            className="intro-title-btn"
            onClick={onClickHide}
            style={{ background: highlightColor }}
          >
            Get started
          </button>
        </div>
        <div className="intro-slide">
          <IntroSlider phone={PHONE} portrait={portrait} image={props.image} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ dimentions, tour }) => ({
  dimentions,
});

export default connect(mapStateToProps)(Intro);
